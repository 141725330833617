import React from 'react';
import {
  Gesture,
  Today,
  Title,
  Person,
  Notes,
  CheckBox,
  FormatStrikethrough,
  LocationOn,
  LocationCity,
  LocalOffer,
  AccessTime,
  CropSquare,
} from '@material-ui/icons';
import { Environment } from '@skyslope/auth-js';

export const config = {
  local: {
    CLARITY_PROJECT_ID: 'bf0w917mkp',
    LAUNCH_DARKLY_CLIENT_ID: '5dae1d82309d840897edaa57',
    OKTA_AUTH_SERVER_ID: 'aus15nfoj3xGfAiHp357',
    OKTA_BASE_URL: 'https://id-integ.skyslope.com',
  },
  dev: {
    REACT_APP_DS_API_URL: 'https://dev-digisign3.skyslope.com/api',
    REACT_APP_DS_AUDIT_URL: 'https://dev-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATES_URL: 'https://dev-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATE_UI_CLIENT_ID: '0oa3ak4arrMBrfT2O357',
    REACT_APP_HEAP_APP_ID: '355210786',
    REACT_APP_SENDER_UI_CLIENT_ID: '0oa55mwl06cACA8CZ357',
    REACT_APP_AZURE_FNF_IDP: '0oaobqdubuAjI3B4E357',
    REACT_APP_CLIENT_ID_FOR_PRIME_AUTH_SERVER: 'lIdc7VaJsPPZRqbH4fNV',
    REACT_APP_FORMS_URL: 'https://integ-forms.skyslope.com/',
    REACT_APP_SIGNER_UI_URL: 'https://dev-sign.skyslope.com/envelope',
    REACT_APP_PRIME_API_URL: 'https://api-integ.skyslope.com/api',
    REACT_APP_PREFERENCES_URL: 'https://dev-send.skyslope.com/preferences',
    REACT_APP_GOOGLE_TAG_MANAGER_ID: '6obidx0T1hlS4BsjJAw1Yw',
    SECURITY_TIMEOUT: 900,
    REACT_APP_ACCOUNT_API_URL: 'https://accounts-integ.skyslope.com/api',
    CLARITY_PROJECT_ID: 'bf0w917mkp',
    LAUNCH_DARKLY_CLIENT_ID: '5dae1d82309d840897edaa57',
    OKTA_AUTH_SERVER_ID: 'aus15nfoj3xGfAiHp357',
    OKTA_BASE_URL: 'https://id-integ.skyslope.com',
    REACT_APP_BROKER_MANAGEMENT_URL: 'https://integ-admin.skyslope.com/people',
    ACCOUNT_ROLES: {
      GROUP_ADMIN: '7a30ea74-a746-4f1e-89b1-04f77fe9d9e1',
      BROKER_ADMIN: 'd88de0bd-7eac-4b47-b81b-a403c0390af4',
    },
    PRIME_INTEGRATION_UI_CLIENT_ID: '0oa54hpiw2DEQUACz357',
    SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED: [
      {
        priceId: 'price_1NKyR7LFv96cntgD2SVAC7V8',
        label: { description: 'DigiSign Annual Subscription', price: '$35.00/year, billed annually' },
      },
      {
        priceId: 'price_1NKyR7LFv96cntgDQlv9UE1E',
        label: { description: 'DigiSign Monthly Subscription', price: '$3.99/month, billed monthly' },
      },
    ],
    SUBSCRIPTION_PRICE_OPTIONS: [
      {
        priceId: 'price_1PtWnBLFv96cntgDzpy79ohw',
        label: { description: 'DigiSign Annual Subscription', price: '$5.42/mo, billed annually' },
      },
      {
        priceId: 'price_1PtWmdLFv96cntgDLvKlEluj',
        label: { description: 'DigiSign Monthly Subscription', price: '$7.99/month, billed monthly' },
      },
    ],
    OKTA_ENV: Environment.Integ,
    STANDALONE_LOGOUT_REDIRECT_URL: 'https://dev-send.skyslope.com',
  },
  integ: {
    REACT_APP_DS_API_URL: 'https://integ-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATES_URL: 'https://integ-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATE_UI_CLIENT_ID: '0oa3ak4arrMBrfT2O357',
    REACT_APP_HEAP_APP_ID: '355210786',
    REACT_APP_SENDER_UI_CLIENT_ID: '0oa4tt1730gynqX0Q357',
    REACT_APP_AZURE_FNF_IDP: '0oaobqdubuAjI3B4E357',
    REACT_APP_CLIENT_ID_FOR_PRIME_AUTH_SERVER: 'ZZom7rwlQQBqvyYGvHyM',
    REACT_APP_FORMS_URL: 'https://integ-forms.skyslope.com/',
    REACT_APP_SIGNER_UI_URL: 'https://integ-sign.skyslope.com/envelope',
    REACT_APP_PRIME_API_URL: 'https://api-integ.skyslope.com/api',
    REACT_APP_PREFERENCES_URL: 'https://integ-send.skyslope.com/preferences',
    REACT_APP_GOOGLE_TAG_MANAGER_ID: 'AC2GoMnqdtM_fTnRg-Fg_g',
    SECURITY_TIMEOUT: 900,
    REACT_APP_ACCOUNT_API_URL: 'https://accounts-integ.skyslope.com/api',
    CLARITY_PROJECT_ID: 'bf0w2qrc7t',
    LAUNCH_DARKLY_CLIENT_ID: '5dae1d9b45554708acda28f4',
    OKTA_AUTH_SERVER_ID: 'aus15nfoj3xGfAiHp357',
    OKTA_BASE_URL: 'https://id-integ.skyslope.com',
    REACT_APP_BROKER_MANAGEMENT_URL: 'https://integ-admin.skyslope.com/people',
    ACCOUNT_ROLES: {
      GROUP_ADMIN: '7a30ea74-a746-4f1e-89b1-04f77fe9d9e1',
      BROKER_ADMIN: 'd88de0bd-7eac-4b47-b81b-a403c0390af4',
    },
    PRIME_INTEGRATION_UI_CLIENT_ID: '0oa4u6lv4tOIKGf8O357',
    SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED: [
      {
        priceId: 'price_1NKyR7LFv96cntgD2SVAC7V8',
        label: { description: 'DigiSign Annual Subscription', price: '$35.00/year, billed annually' },
      },
      {
        priceId: 'price_1NKyR7LFv96cntgDQlv9UE1E',
        label: { description: 'DigiSign Monthly Subscription', price: '$3.99/month, billed monthly' },
      },
    ],
    SUBSCRIPTION_PRICE_OPTIONS: [
      {
        priceId: 'price_1PtWnBLFv96cntgDzpy79ohw',
        label: { description: 'DigiSign Annual Subscription', price: '$5.42/mo, billed annually' },
      },
      {
        priceId: 'price_1PtWmdLFv96cntgDLvKlEluj',
        label: { description: 'DigiSign Monthly Subscription', price: '$7.99/month, billed monthly' },
      },
    ],
    OKTA_ENV: Environment.Integ,
    STANDALONE_LOGOUT_REDIRECT_URL: 'https://integ-send.skyslope.com',
  },
  staging: {
    REACT_APP_DS_API_URL: 'https://staging-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATES_URL: 'https://staging-digisign3.skyslope.com/api',
    REACT_APP_TEMPLATE_UI_CLIENT_ID: '0oaqkbm86fvmCmY6B0h7',
    REACT_APP_HEAP_APP_ID: '1691345378',
    REACT_APP_DATADOG_ENV: 'staging',
    REACT_APP_SENDER_UI_CLIENT_ID: '0oasvmf5tbnbrjguK0h7',
    REACT_APP_AZURE_FNF_IDP: '0oa1hcw4wfuS0bSTa0h8',
    REACT_APP_CLIENT_ID_FOR_PRIME_AUTH_SERVER: 'BvVJfA5l65ICbULHQeHf',
    REACT_APP_FORMS_URL: 'https://staging-forms.skyslope.com/',
    REACT_APP_SIGNER_UI_URL: 'https://staging-sign.skyslope.com/envelope',
    REACT_APP_PRIME_API_URL: 'https://api-staging.skyslope.com/api',
    REACT_APP_PREFERENCES_URL: 'https://staging-send.skyslope.com/preferences',
    REACT_APP_GOOGLE_TAG_MANAGER_ID: '9OVXeDhfb1ILj5G0lpxTtw',
    SECURITY_TIMEOUT: 900,
    REACT_APP_ACCOUNT_API_URL: 'https://accounts-staging.skyslope.com/api',
    CLARITY_PROJECT_ID: 'beyll6o5t8',
    LAUNCH_DARKLY_CLIENT_ID: '5dae1db245554708acda28f7',
    OKTA_AUTH_SERVER_ID: 'ausq0hw1h65nne9mH0h7',
    OKTA_BASE_URL: 'https://id-staging.skyslope.com',
    REACT_APP_BROKER_MANAGEMENT_URL: 'https://staging-admin.skyslope.com/people',
    ACCOUNT_ROLES: {
      GROUP_ADMIN: '7a30ea74-a746-4f1e-89b1-04f77fe9d9e1',
      BROKER_ADMIN: 'd88de0bd-7eac-4b47-b81b-a403c0390af4',
    },
    PRIME_INTEGRATION_UI_CLIENT_ID: '0oasvmbr6zMk4bkUF0h7',
    SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED: [
      {
        priceId: 'price_1NKyR7LFv96cntgD2SVAC7V8',
        label: { description: 'DigiSign Annual Subscription', price: '$35.00/year, billed annually' },
      },
      {
        priceId: 'price_1NKyR7LFv96cntgDQlv9UE1E',
        label: { description: 'DigiSign Monthly Subscription', price: '$3.99/month, billed monthly' },
      },
    ],
    SUBSCRIPTION_PRICE_OPTIONS: [
      {
        priceId: 'price_1PtWnBLFv96cntgDzpy79ohw',
        label: { description: 'DigiSign Annual Subscription', price: '$5.42/mo, billed annually' },
      },
      {
        priceId: 'price_1PtWmdLFv96cntgDLvKlEluj',
        label: { description: 'DigiSign Monthly Subscription', price: '$7.99/month, billed monthly' },
      },
    ],
    OKTA_ENV: Environment.Staging,
    STANDALONE_LOGOUT_REDIRECT_URL: 'https://staging-send.skyslope.com',
  },
  prod: {
    REACT_APP_DS_API_URL: 'https://digisign3.skyslope.com/api',
    REACT_APP_TEMPLATES_URL: 'https://digisign3.skyslope.com/api',
    REACT_APP_TEMPLATE_UI_CLIENT_ID: '0oa4yvtikyN0RBDI34x6',
    REACT_APP_HEAP_APP_ID: '3954488189',
    REACT_APP_DATADOG_ENV: 'prod',
    REACT_APP_SENDER_UI_CLIENT_ID: '0oam9zo1eK0Td9fhm4x6',
    REACT_APP_AZURE_FNF_IDP: '0oaanuqiyrjdGz5g14x7',
    REACT_APP_CLIENT_ID_FOR_PRIME_AUTH_SERVER: 'S9WIdySIsIn3P7JhzCFp',
    REACT_APP_FORMS_URL: 'https://forms.skyslope.com/',
    REACT_APP_SIGNER_UI_URL: 'https://sign.skyslope.com/envelope',
    REACT_APP_PRIME_API_URL: 'https://api.skyslope.com/api',
    REACT_APP_PREFERENCES_URL: 'https://send.skyslope.com/preferences',
    REACT_APP_GOOGLE_TAG_MANAGER_ID: '8WAA4RAALtwpjBysEvBZig',
    SECURITY_TIMEOUT: 900,
    REACT_APP_ACCOUNT_API_URL: 'https://accounts.skyslope.com/api',
    CLARITY_PROJECT_ID: 'bejvbcgk05',
    LAUNCH_DARKLY_CLIENT_ID: '5dae1dcd45554708acda28fa',
    OKTA_AUTH_SERVER_ID: 'aus2ukovqrWcy6TUw4x6',
    OKTA_BASE_URL: 'https://id.skyslope.com',
    REACT_APP_BROKER_MANAGEMENT_URL: 'https://admin.skyslope.com/people',
    ACCOUNT_ROLES: {
      GROUP_ADMIN: '7a30ea74-a746-4f1e-89b1-04f77fe9d9e1',
      BROKER_ADMIN: 'd88de0bd-7eac-4b47-b81b-a403c0390af4',
    },
    PRIME_INTEGRATION_UI_CLIENT_ID: '0oama1gqxZ1rVnCCd4x6',
    SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED: [
      {
        priceId: 'price_1NLZ7lLFv96cntgDOrc3G2gx',
        label: { description: 'DigiSign Annual Subscription', price: '$35.00/year, billed annually' },
      },
      {
        priceId: 'price_1NLZ7lLFv96cntgDJ8XvBsmv',
        label: { description: 'DigiSign Monthly Subscription', price: '$3.99/month, billed monthly' },
      },
    ],
    SUBSCRIPTION_PRICE_OPTIONS: [
      {
        priceId: 'price_1PnQH0LFv96cntgDykpHKwhd',
        label: { description: 'DigiSign Annual Subscription', price: '$5.42/mo, billed annually' },
      },
      {
        priceId: 'price_1PnQInLFv96cntgD4XpL61jT',
        label: { description: 'DigiSign Monthly Subscription', price: '$7.99/month, billed monthly' },
      },
    ],
    OKTA_ENV: Environment.Prod,
    STANDALONE_LOGOUT_REDIRECT_URL: 'https://skyslope.com/digisign-login',
    STANDALONE_LOGIN_URL: 'https://skyslope.com/digisign-login',
  },
};

// @ts-ignore
const environmentConfig = config[window.DS3_ENV] || config.dev;
// @ts-ignore
window.DS3_CONFIG = environmentConfig;

// @ts-ignore
export const env = config[window.DS3_ENV] ? window.DS3_ENV : 'dev';
export const API_URL = environmentConfig.REACT_APP_DS_API_URL;
export const AUDIT_URL = environmentConfig.REACT_APP_DS_AUDIT_URL || API_URL;
export const TEMPLATES_URL = environmentConfig.REACT_APP_TEMPLATES_URL || API_URL;
export const templateUIClientId = environmentConfig.REACT_APP_TEMPLATE_UI_CLIENT_ID;
export const DATADOG_ENV = environmentConfig.REACT_APP_DATADOG_ENV;
export const LAUNCH_DARKLY_CLIENT_ID = environmentConfig.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
export const senderUIClientId = environmentConfig.REACT_APP_SENDER_UI_CLIENT_ID;
export const azureFNFIDP = environmentConfig.REACT_APP_AZURE_FNF_IDP;
export const primeAuthClientId = environmentConfig.REACT_APP_CLIENT_ID_FOR_PRIME_AUTH_SERVER;
export const formsUrl = environmentConfig.REACT_APP_FORMS_URL;
export const standaloneLogoutRedirectUrl = environmentConfig.STANDALONE_LOGOUT_REDIRECT_URL;
export const standaloneLoginUrl = environmentConfig.STANDALONE_LOGIN_URL;
export const signerUiUrl = environmentConfig.REACT_APP_SIGNER_UI_URL;
export const primeApiUrl = environmentConfig.REACT_APP_PRIME_API_URL;
export const preferencesUrl = environmentConfig.REACT_APP_PREFERENCES_URL;
export const timeoutInterval = environmentConfig.SECURITY_TIMEOUT;
export const GOOGLE_TAG_MANAGER_ID = environmentConfig.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const accountApiUrl = environmentConfig.REACT_APP_ACCOUNT_API_URL;
export const { ACCOUNT_ROLES } = environmentConfig;
export const brokerManagementUrl = environmentConfig.REACT_APP_BROKER_MANAGEMENT_URL;
export const integrationUIClientId = environmentConfig.PRIME_INTEGRATION_UI_CLIENT_ID;
export const oktaEnv = environmentConfig.OKTA_ENV;
export const oktaDS3Iss = environmentConfig.OKTA_BASE_URL + '/oauth2/' + environmentConfig.OKTA_AUTH_SERVER_ID;

export const SHELF_WIDTH = 360;
export const SKELETON_WIDTH = 8.5 * 72;
export const SKELETON_HEIGHT = 11 * 72;

export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 2.5;
export const ZOOM_INCREMENT = 0.25;

export const MIN_BLOCK_SIZE = 9;

export const ARROW_KEY_MOVEMENT_INCREMENT = 6;

export const { SUBSCRIPTION_PRICE_OPTIONS, SUBSCRIPTION_PRICE_OPTIONS_DEPRECATED } = environmentConfig;

export const priceComparisonLink = 'https://skyslope.com/digisign-price-comparison/';

export enum ResourceNames {
  ENVELOPE = 'envelope',
  TEMPLATE = 'template',
}

export enum EnvelopeStatuses {
  ALL = 'All',
  COMPLETED = 'Completed',
  SENT = 'Sent',
  DRAFT = 'Draft',
  INPROGRESS = 'InProgress',
  CANCELED = 'Canceled',
  CORRECTING = 'Correcting',
}

export const BLOCK_TYPE_KEYS = {
  SIGNATURE: 'Signature',
  INITIALS: 'Initials',
  DATE: 'DateSigned',
  FULL_NAME: 'FullName',
  TEXT: 'Text',
  CHECKBOX: 'Checkbox',
  MULTIPLE: 'Multiple',
  STRIKE: 'Strike',
  TIME: 'TimeSigned',
  RECTANGLE: 'Rectangle',
};

export const tagIcons = {
  locationOn: <LocationOn />,
  locationCity: <LocationCity />,
  person: <Person />,
  localOffer: <LocalOffer />,
};

export const readOnlyKey = 'readOnly';
export const requiredKey = 'required';

export const BLOCK_PROPERTIES = {
  required: {
    key: requiredKey,
    label: 'Optional Field',
    flipValue: true,
  },
  readOnly: {
    key: readOnlyKey,
    label: 'Read Only',
  },
  checked: {
    key: 'value',
    label: 'Checked by Default',
  },
};

export const MIN_CHECKBOX_SIZE = 2;
export const DEFAULT_CHECKBOX_SIZE = 12;
export const STRIKE_LINE_WIDTH = 2;

export const BLOCK_TYPES = [
  {
    key: BLOCK_TYPE_KEYS.MULTIPLE,
    name: 'Multiple',
  },
  {
    key: BLOCK_TYPE_KEYS.SIGNATURE,
    name: 'Signature',
    icon: <Gesture />,
    size: {
      default: {
        width: 116,
        height: 16,
      },
      min: {
        width: 44,
        height: 12,
      },
      max: {
        width: 336,
        height: 48,
      },
      fontSize: {
        default: 15,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
    },
    properties: [requiredKey],
    mobileOptions: ['Optional', 'Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.INITIALS,
    name: 'Initials',
    icon: <Title />,
    size: {
      default: {
        width: 20,
        height: 16,
      },
      min: {
        width: 16,
        height: 12,
      },
      max: {
        width: 96,
        height: 80,
      },
      fontSize: {
        default: 10,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
    },
    properties: [requiredKey],
    mobileOptions: ['Optional', 'Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.FULL_NAME,
    name: 'Full Name',
    icon: <Person />,
    size: {
      default: {
        width: 116,
        height: 16,
      },
      min: {
        width: 88,
        height: 12,
      },
      max: {
        width: 336,
        height: 48,
      },
      fontSize: {
        default: 11,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
    },
    properties: [],
    mobileOptions: ['Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.DATE,
    name: 'Date',
    icon: <Today />,
    size: {
      default: {
        width: 70,
        height: 16,
      },
      min: {
        width: 24,
        height: 12,
      },
      max: {
        width: 120,
        height: 24,
      },
      fontSize: {
        default: 10,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
    },
    properties: [],
    mobileOptions: ['Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.TIME,
    name: 'Time',
    icon: <AccessTime />,
    size: {
      default: {
        width: 116,
        height: 16,
      },
      min: {
        width: 24,
        height: 12,
      },
      max: {
        width: 120,
        height: 24,
      },
      fontSize: {
        default: 8,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
    },
    properties: [],
    mobileOptions: ['Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.CHECKBOX,
    name: 'Checkbox',
    icon: <CheckBox />,
    size: {
      default: {
        width: DEFAULT_CHECKBOX_SIZE,
        height: DEFAULT_CHECKBOX_SIZE,
      },
      min: {
        width: MIN_CHECKBOX_SIZE,
        height: MIN_CHECKBOX_SIZE,
      },
      max: {
        width: DEFAULT_CHECKBOX_SIZE * 5,
        height: DEFAULT_CHECKBOX_SIZE * 5,
      },
      fontSize: {
        default: 3,
      },
    },
    options: {
      assignRequired: true,
      hasText: false,
      checkboxGroupControls: true,
      readonly: true,
    },
    properties: [readOnlyKey, 'checked'],
    mobileOptions: ['Read Only', 'Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.TEXT,
    name: 'Text Field',
    icon: <Notes />,
    size: {
      default: {
        width: 116,
        height: 16,
      },
      min: {
        width: 24,
        height: 12,
      },
      max: {
        width: 10000,
        height: 10000,
      },
      fontSize: {
        default: 15,
      },
    },
    options: {
      assignRequired: true,
      hasText: true,
      optionalByDefault: true,
      readonly: true,
    },
    properties: [requiredKey, readOnlyKey],
    mobileOptions: ['Optional', 'Read Only', 'Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.STRIKE,
    name: 'Strike',
    icon: <FormatStrikethrough />,
    size: {
      default: {
        width: 20,
        height: STRIKE_LINE_WIDTH,
      },
      min: {
        width: 5,
        height: STRIKE_LINE_WIDTH,
      },
      max: {
        width: 10000,
        height: 10000,
      },
      fontSize: {
        default: null,
      },
    },
    options: {
      assignRequired: false,
      hasText: false,
    },
    properties: [],
    mobileOptions: ['Delete'],
  },
  {
    key: BLOCK_TYPE_KEYS.RECTANGLE,
    name: 'Rectangle',
    icon: <CropSquare />,
    size: {
      default: {
        width: 5,
        height: 5,
      },
      min: {
        width: 5,
        height: 5,
      },
      max: {
        width: 10000,
        height: 10000,
      },
      fontSize: {
        default: null,
      },
    },
    options: {
      assignRequired: false,
      hasText: false,
    },
    properties: [],
    mobileOptions: ['Delete'],
  },
];

export const SIGNER_COLORS = [
  '#F78812', // orange
  '#00ACC1', // teal
  '#845FDA', // purple
  '#079EFF', // blue
  '#FCCA4E', // yellow
  '#67D3BD', // green
  '#EF538B', // pink
];

export const EMAIL_BODY_LENGTH = 999;
export const EMAIL_SUBJECT_LENGTH = 99;

export const eventNames = {
  EnvelopeCreated: 'EnvelopeCreated',
  EnvelopeCanceled: 'EnvelopeCanceled',
  EnvelopeCompleted: 'EnvelopeCompleted',
  EnvelopeCorrecting: 'EnvelopeCorrecting',
  EnvelopeSent: 'EnvelopeSent',
  ESignDisclosureAccepted: 'ESignDisclosureAccepted',
  EnvelopeSigned: 'EnvelopeSigned',
  EnvelopeViewed: 'EnvelopeViewed',
  EnvelopeDownloaded: 'EnvelopeDownloaded',
  NotificationRequested: 'NotificationRequested',
  WaitingFor: 'WaitingFor',
  SignerUpdated: 'SignerUpdated',
  SignerAdded: 'SignerAdded',
  SignerDeleted: 'SignerDeleted',
  CarbonCopyAdded: 'CarbonCopyAdded',
  CarbonCopyDeleted: 'CarbonCopyDeleted',
  CarbonCopyUpdated: 'CarbonCopyUpdated',
  SignatureAccepted: 'SignatureAccepted',
  EnvelopeNameUpdated: 'EnvelopeNameUpdated',
};

export enum emailTypes {
  ReviewAndSign = 'ReviewAndSign',
  Canceled = 'EnvelopeCanceled',
  Completed = 'EnvelopeCompleted',
}

export const signingGroupOrder = {
  0: 'First',
  1: 'Second',
  2: 'Third',
  3: 'Fourth',
};

export const chameleonElId = (num: number) => {
  return `ds-chameleon-${num}`;
};

export const appMenuLinks = {
  'dev-skyslope': 'https://integ.skyslope.com',
  'integ-skyslope': 'https://integ.skyslope.com',
  'staging-skyslope': 'https://staging.skyslope.com',
  'send.skyslope.com-skyslope': 'https://app.skyslope.com',
  'dev-forms': 'https://integ-forms.skyslope.com',
  'integ-forms': 'https://integ-forms.skyslope.com',
  'staging-forms': 'https://staging-forms.skyslope.com',
  'send.skyslope.com-forms': 'https://forms.skyslope.com',
  'dev-breeze': 'https://integ-breeze.skyslope.com',
  'integ-breeze': 'https://integ-breeze.skyslope.com',
  'staging-breeze': 'https://staging-breeze.skyslope.com',
  'send.skyslope.com-breeze': 'https://breeze.skyslope.com',
};

export const statusOrder = {
  Active: 1,
  Incomplete: 2,
  Pending: 3,
  Expired: 4,
};

export const StrikeColors = {
  Red: '#cb0006',
  Black: '#000000',
};
