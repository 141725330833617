import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import { getRecipients, updateRecipients } from '../lib/api/envelope/recipients';
import { preferencesUrl } from '../lib/constants';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import * as actions from '../store/senderBuild/actions';
import Spinner from '../components/common/Spinner';
import { successToast, errorToast } from '../store/pageFrame/actions';
import Footer from '../components/common/Footer';
import { IRootState } from '../store';
import { ISigner } from '../store/senderBuild/types';
import { HeaderContext } from '../context/header-context';
import FooterLinks from '../components/common/FooterLinks';
import { ISettings } from '../store/pageFrame/types';
import { getUsersGroups } from '../lib/api/account/accountApi';
import NavHeader from '../common/navigation';
import Recipients from '../common/recipients';
import { RecipientRole, IRecipient, IGroup } from '../common/recipients/types';
import { checkIfRecipientIsSender } from '../common/utils';
import { UserOrigins } from '../lib/types';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import useUserInfo from '../auth/useUserInfo';
import { applyColorAndCounterToNewRecipient, getRecipientColor, sortRecipients } from '../lib/utils';
import { isSmallScreenMediaQuery } from '../lib/isSmallScreen';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  header: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  wrapper: {
    width: '100%',
    maxWidth: 1200,
    maxHeight: 639,
    height: '100%',
  },
  gridModal: {
    gridTemplateColumns: '380px 1fr',
    border: 'none',
  },
  grid: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    display: 'grid',
    gridTemplateColumns: '530px 1fr',
    gridAutoFlow: 'column',
  },
  noGrid: {
    display: 'inherit !important',
  },
  backButton: {
    marginRight: 'auto',
  },
  nextButton: {
    padding: '10px 40px',
  },
}));

interface IProps {
  modal?: boolean;
  flags?: LaunchDarklyFlags;
  disableRecipientList?: boolean;
}

interface IState {
  signers: ISigner[];
  settings: ISettings;
  settingsFetched: boolean;
  contacts: any[];
  colorCounter: number;
}

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
  colorCounter: state.pageFrame.recipientColorCounter,
  contacts: state.senderBuild.recipientsModal.contacts,
});

const RecipientManagement = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { signers, contacts, settings, settingsFetched, colorCounter }: IState = useShallowSelector(selector);
  const userInfo = useUserInfo();
  const { modal, flags, disableRecipientList } = props;
  const params = useParams<{ id: string }>();
  const classes = useStyles();
  const [recipients, setRecipients] = React.useState<IRecipient[]>([]);
  const [fetching, setFetching] = React.useState(true);
  const [incompletePlaceholders, setIncompletePlaceholders] = React.useState<IRecipient[]>();
  const [isPrimeUser, setIsPrimeUser] = React.useState(false);
  const [optionalRecipients, setOptionalRecipients] = React.useState<IRecipient[]>();
  const isMeRecipient = useRef(false);
  const headerContext = React.useContext(HeaderContext);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const enableTemplateAccess = flags && flags['enable-templates-access'];
  const isSmallScreen = isSmallScreenMediaQuery();
  const [isInit, setIsInit] = useState(false);

  const handleNextButton = async () => {
    if (recipients.length) {
      setFetching(true);
      try {
        const updatedRecipients: IRecipient[] = recipients.slice();
        optionalRecipients?.forEach(or => {
          if (!updatedRecipients.find(ur => ur.id === or.id) && !checkIfRecipientIsSender(or)) {
            updatedRecipients.push(or);
          }
        });
        incompletePlaceholders?.forEach(ip => {
          if (!updatedRecipients.find(ur => ip.id === ur.id)) {
            updatedRecipients.push(ip);
          }
        });
        await updateRecipients(updatedRecipients, params.id);
        history.push(`/envelopes/${params.id}`);
        dispatch(successToast('Recipient changes saved.'));
      } catch (e) {
        dispatch(errorToast('Error saving recipients.'));
      } finally {
        setFetching(false);
      }
    }
  };
  const handleCreatePrimeContact = (contact: { firstName: string; lastName: string; email: string }) => {
    dispatch(actions.createPrimeContact(contact));
  };

  const getOptionalRecipients = (recipients: IRecipient[], sender: IRecipient) => {
    return recipients.filter(r => r.isPlaceholder && (r.firstName || r.email) && !checkIfRecipientIsSender(r));
  };

  const validateSenderColor = (recipients: IRecipient[], sender: IRecipient) => {
    // Validate sender recipient color is correct. The default color is blue[800], but if this recipient gets saved to the envelope
    // it could have a different placeholder. Carbon Copy recipients don't have color correctly assigned to them. We have a ticket to address this.
    // To get around this, if a sender recipient is active and also a CC they won't have a color. This check below will handle that case until colors are fixed.
    const senderRecipient = recipients.find(
      r => r.email?.toLowerCase() === userInfo!.senderEmail.toLowerCase() && r.title?.toLowerCase() === 'me'
    );
    if (senderRecipient && (senderRecipient.color || senderRecipient?.role === RecipientRole.CarbonCopy)) {
      sender.color = senderRecipient.color;
      sender.isActive = true;
      sender.role = senderRecipient.role;
    }
  };

  // Filters Me from incomplete placeholders if needed
  const getIncompletePlaceHolders = (recipients: IRecipient[]) => {
    const incompletePlaceholders = recipients.filter(
      r => r.isPlaceholder && (r.firstName === null || r.email === null)
    );

    return !isMeRecipient.current
      ? incompletePlaceholders
      : incompletePlaceholders?.filter(r => r.title?.toLocaleLowerCase() !== 'me');
  };

  // Adds sender as Me placeholder
  const addSenderToOptional = (optionalRecipients: IRecipient[], recipients: IRecipient[], sender: IRecipient) => {
    if (optionalRecipients.some(or => checkIfRecipientIsSender(or))) {
      return;
    }

    // Add sender as optionalRecipients
    optionalRecipients.unshift(sender);

    // Check if me if a placeholder
    const index = recipients.indexOf(recipients.find((r: IRecipient) => r.title?.toLowerCase() === 'me'));
    if (index < 0) return;

    // If recipient hasn't been assigned before, the email in recipient Me should be empty
    if (!recipients[index].email) {
      optionalRecipients[0].id = recipients[index].id;
      optionalRecipients[0].group = { id: 'default', name: 'default', order: 0 };
      optionalRecipients[0].isActive = true;
      optionalRecipients[0].isPlaceholder = true;
      recipients[index] = { ...optionalRecipients[0] };
    } else {
      recipients[index].title = recipients[index].title ?? 'Me';
      recipients[index].isSender = true;
      recipients[index].group = recipients[index].group ?? { id: 'default', name: 'default', order: 0 };
      recipients[index].color = colors.blue[800];
      optionalRecipients[0] = { ...recipients[index] };
    }
    isMeRecipient.current = true;
  };

  const setupRecipients = (recipients: IRecipient[]) => {
    const sender: IRecipient = {
      id: userInfo!.senderId,
      role: RecipientRole.Signer,
      firstName: userInfo!.senderFirstName!,
      lastName: userInfo!.senderLastName!,
      email: userInfo!.senderEmail!,
      isSender: true,
      color: colors.blue[800],
      isActive: false,
      isPlaceholder: false,
      title: 'Me',
      recipientColorCounter: 0,
    };

    const optionalRecipients = modal ? [] : getOptionalRecipients(recipients, sender);

    addSenderToOptional(optionalRecipients, recipients, sender);

    validateSenderColor(recipients, sender);

    const incompletePlaceholders = getIncompletePlaceHolders(recipients);
    setRecipients(recipients);
    setOptionalRecipients(optionalRecipients);
    setIncompletePlaceholders(incompletePlaceholders);
  };

  useEffect(() => {
    if (!settingsFetched || !userInfo || isInit) return;
    setIsInit(true);
    (async () => {
      const recipients = await getRecipients(params.id, true);
      sortRecipients(recipients);
      if (!contacts.length) {
        dispatch(actions.fetchContacts());
      }
      if (modal) {
        dispatch(actions.setRecipientsLoading(true));
        recipients.forEach((r, i) => {
          if (r.title === 'Me') return;
          if (r.role === RecipientRole.CarbonCopy) {
            r.color = getRecipientColor(r.recipientColorCounter);
          } else {
            r.color = signers.find(s => s.signerId === r.id)?.color;
          }
        });
        setupRecipients(recipients);
        setFetching(false);
        dispatch(actions.setRecipientsLoading(false));
        return;
      }
      // Assign a color to each recipient
      recipients.forEach((r, i) => {
        r.color = getRecipientColor(r.recipientColorCounter, r.title);
      });
      setupRecipients(recipients);
      setFetching(false);
    })();
    if (!modal) {
      headerContext.setContent(
        <NavHeader
          labelName="Add recipients"
          breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
          progressBarPercent={75}
          hideNavItems={hideNavItems}
          showBreezeApp={showBreezeApp}
          preferencesUrl={preferencesUrl}
          showPreferences={showPreferencesMenuItem}
          userOrigin={settings?.account.userOrigin}
          getUsersGroups={getUsersGroups}
          flags={flags}
        />
      );
    }
    return () => {
      if (!modal) {
        headerContext.setContent(null);
      }
    };
  }, [settingsFetched, userInfo, isInit]);

  useEffect(() => {
    if (settingsFetched) {
      const isPrimeOrigin = settings.account.userOrigin === UserOrigins.Prime;
      if (isPrimeOrigin && userInfo?.isPrimeAuthenticated) {
        setIsPrimeUser(true);
      }
    }
  }, [settingsFetched, userInfo?.isPrimeAuthenticated]);

  const onChange = (newRecipients: IRecipient[], newGroups: IGroup[]) => {
    applyColorAndCounterToNewRecipient(newRecipients, recipients, colorCounter);
    sortRecipients(newRecipients);
    setRecipients(newRecipients);
    if (modal) {
      const newRecipientsTemp = newRecipients.concat(incompletePlaceholders);
      newRecipients = newRecipientsTemp.filter((nr, i) => newRecipientsTemp.indexOf(nr) === i);
      dispatch(actions.setRecipientsAndSignerGroupsModal(newRecipients, newGroups));
    }
  };

  if (fetching) {
    return modal ? <Spinner /> : null;
  }

  const nextDisabled = !recipients.length || !recipients.some(r => r.role === RecipientRole.Signer && r.isActive);

  const footer = modal ? null : (
    <Footer>
      {hideNavItems ? (
        ''
      ) : (
        <>
          <Button className={classes.backButton} color="primary" onClick={() => window.history.back()}>
            <BackIcon fontSize="small" /> Back
          </Button>
          <Button color="primary" data-spec="exit" onClick={() => history.push('/envelopes')}>
            Exit
          </Button>
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        data-spec="next"
        onClick={handleNextButton}
        className={classes.nextButton}
        disabled={nextDisabled}
      >
        Next
      </Button>
    </Footer>
  );

  const title = modal ? null : (
    <div className={isSmallScreen ? 'pt-0 p-8 text-center' : classes.header}>
      <Typography variant="h3">Recipients & signing order</Typography>
      <Typography component="span" variant="body2" style={{ marginTop: 8 }}>
        Add recipients by clicking on the “+ New Recipient” button in the left side panel
      </Typography>
    </div>
  );

  const gridClass = props.disableRecipientList ? `${classes.grid} ${classes.noGrid}` : classes.grid;

  return (
    <div className={classes.root} style={{ height: modal ? '100%' : undefined }}>
      <div className={classes.wrapper}>
        {title}
        <div
          className={gridClass}
          style={
            modal
              ? {
                  gridTemplateColumns: '380px 1fr',
                  border: 'none',
                  height: '100%',
                }
              : {
                  minHeight: '55vh',
                }
          }
        >
          <Recipients
            recipients={recipients}
            optionalRecipients={optionalRecipients}
            incompletePlaceholders={incompletePlaceholders}
            onChange={onChange}
            createPrimeContact={handleCreatePrimeContact}
            colorCounter={colorCounter}
            isPrimeUser={isPrimeUser}
            contacts={contacts}
            modal={modal}
            disableRecipientList={!!props.disableRecipientList}
          />
        </div>
        {footer}
        {!modal && !disableRecipientList && <FooterLinks />}
      </div>
    </div>
  );
};

export default withLaunchDarkly(RecipientManagement);
