import React, { useState } from 'react';
import { colors } from '@skyslope/mache';
import { makeStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as SkyslopeIcon } from '../../images/skyslope_icon.svg';
import { ReactComponent as FormsIcon } from '../../images/forms_icon.svg';
import { ReactComponent as BreezeIcon } from '../../images/breeze_icon.svg';
import { appMenuLinks } from '../../lib/constants';
import { isAppRunningInIframe } from '../utils';

const useStyles = makeStyles(() => ({
  navLinks: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  navApps: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '14px',
    letterSpacing: '.4px',
    color: colors.grey[800],
  },
  navAppsContent: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
    padding: '6px 8px',
    fontSize: '16px',
    color: colors.grey[800],
    '&:hover,&:focus': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
      borderRadius: '20px',
      outline: 'none',
    },
  },
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  menuTitle: {
    fontWeight: 800,
    '&:hover,&:focus': {
      backgroundColor: '#fff',
      cursor: 'default',
    },
  },
  appItem: {
    '&:hover,&:focus': {
      backgroundColor: colors.blue[50],
      cursor: 'pointer',
      color: '#0059DA',
    },
  },
}));

interface IProps {
  showBreezeApp: boolean;
}

const AppMenu = (props: IProps) => {
  const classes = useStyles();
  const { showBreezeApp } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const isInIframe = isAppRunningInIframe();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleKeyDown = (event: React.KeyboardEvent<EventTarget>) => {
    if (event.key === 'Enter') {
      handleToggle();
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleAppClick = (app: 'forms' | 'skyslope' | 'breeze') => {
    let currentEnv = window.location.hostname.split('-')[0];
    currentEnv = currentEnv === 'localhost' ? 'dev' : currentEnv;
    const url = appMenuLinks[`${currentEnv}-${app}`];
    window.location.href = url;
  };
  return (
    !isInIframe && (
      <>
        <span
          className={classes.navAppsContent}
          onClick={handleToggle}
          onKeyDown={handleKeyDown}
          ref={anchorRef}
          tabIndex={0}
          id="appsMenu"
        >
          <AppsIcon style={{ marginRight: '4px' }} /> Apps
        </span>
        <Popper className="z-10" open={open} anchorEl={anchorRef.current} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem className={classes.menuTitle} tabIndex={-1}>
                      Switch to:
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      className={classes.appItem}
                      onClick={() => {
                        handleAppClick('skyslope');
                      }}
                    >
                      <SkyslopeIcon className={classes.icon} /> SKYSLOPE
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      className={classes.appItem}
                      onClick={() => {
                        handleAppClick('forms');
                      }}
                    >
                      <FormsIcon className={classes.icon} /> FORMS
                    </MenuItem>
                    {showBreezeApp ? (
                      <MenuItem
                        tabIndex={0}
                        className={classes.appItem}
                        onClick={() => {
                          handleAppClick('breeze');
                        }}
                      >
                        <BreezeIcon className={classes.icon} /> BREEZE
                      </MenuItem>
                    ) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  );
};

export default AppMenu;
