import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '@skyslope/mache';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import { Checkbox } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import Table from '../common/Table';
import Spinner from '../common/Spinner';
import { IRootState } from '../../store';
import { concatObject, getRecipientGroup } from '../../lib/utils';
import { emailTypes, eventNames, signingGroupOrder } from '../../lib/constants';
import { useShallowSelector } from '../../lib/reduxHooks';
import { hideAlert, successToast } from '../../store/pageFrame/actions';
import { sendEmail } from '../../store/envelopeHistory/actions';
import { fetchContacts } from '../../store/senderBuild/actions';
import EditRecipientForm from './EditRecipientForm';
import {
  IEnvelopeHistoryBody,
  IRecipientBody,
  IGroupDetails,
  IAuditHistoryBody,
  IEventData,
} from '../../store/envelopeHistory/types';
import { Alert } from '../common/Alert';
import { IAlert } from '../../store/pageFrame/types';
import { checkIfRecipientIsSender } from '../../common/utils';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalText, ModalTitle } from '../../common/Modal';
import { IContact } from '../../lib/types';
import { getAuditHistory } from '../../lib/api/envelope/envelope';

interface IState {
  isFetching: boolean;
  envelope: IEnvelopeHistoryBody;
  alert: IAlert;
  contacts: IContact[];
}

const selector = (state: IRootState) => ({
  isFetching: state.envelopeHistory.isFetching,
  envelope: state.envelopeHistory.envelope,
  alert: state.pageFrame.alert,
  contacts: state.senderBuild.recipientsModal.contacts,
});

export default function ResendModal(props: { close: () => void }) {
  const dispatch = useDispatch();
  const [disableSenderInput, setDisableSenderInput] = React.useState(false);
  const [selectedRecipients, setSelectedRecipients] = React.useState<string[]>([]);
  const { envelope, isFetching, alert, contacts }: IState = useShallowSelector(selector);
  const [recipientToEdit, setRecipientToEdit] = React.useState<null | IRecipientBody>(null);

  const { data: auditHistory } = useQuery(['auditHistory'], async () => getAuditHistory(envelope.id), {
    enabled: !!envelope.id,
    select: (data: IAuditHistoryBody[]) => {
      const reviewAndSignEvents = data?.filter(
        // @ts-ignore
        (e) => e.eventName === eventNames.NotificationRequested && e.eventData?.emailType === emailTypes.ReviewAndSign
      );

      return reviewAndSignEvents?.reduce((acc: Record<string, Date>, e) => {
        const { recipientId } = e.eventData as IEventData;
        if (recipientId) {
          if (!acc[recipientId] || acc[recipientId] < new Date(e.timeStamp)) {
            acc[recipientId] = new Date(e.timeStamp);
          }
        }
        return acc;
      }, {});
    },
  });

  useEffect(() => {
    if (!contacts.length) {
      dispatch(fetchContacts());
    }
    return () => {
      if (recipientToEdit) {
        setRecipientToEdit(null);
      }
    };
  }, []);

  React.useEffect(() => {
    if (alert.visible) {
      document.getElementById('alert')!.focus();
      document.getElementById('alert')!.tabIndex = 1;
      document.getElementById('alert')!.style.outline = 'none';
      setTimeout(() => {
        dispatch(hideAlert());
      }, 4000);
    }
  }, [alert.visible]);

  const recipientsInProgress = envelope.recipients
    .filter((r: IRecipientBody) => r.status === 'InProgress')
    .map((r: IRecipientBody) => ({
      ...r,
      order: getRecipientGroup(r, envelope)?.order || 0,
      lastSent: formatDate(auditHistory?.[r.id]),
    }))
    .sort((a: IRecipientBody, b: IRecipientBody) => a.order! - b.order!);

  const truncateText = (text: string, length = 16) => (text.length > length ? `${text.substring(0, length)}...` : text);

  function sendReminders(recipientIds: string[]) {
    recipientIds.forEach((recipientId) => {
      dispatch(
        sendEmail(envelope.id, {
          recipient: { recipientId },
          emailType: 'ReviewAndSign',
          status: 'Sent',
          isResend: 'true',
        })
      );
    });
    dispatch(successToast('Envelope successfully resent to recipients(s)'));
    props.close();
  }

  function handleEditRecipient(r: IRecipientBody) {
    const isSender = checkIfRecipientIsSender(r);
    if (isSender) {
      setDisableSenderInput(true);
    } else setDisableSenderInput(false);
    setRecipientToEdit(r);
  }

  function isInActiveSignerGroup(r: IRecipientBody) {
    const group = envelope.signerDetails?.details?.find((d: IGroupDetails) => d.value.signerId === r.id);
    return group?.value.signingGroupId === envelope?.signerDetails?.activeGroup;
  }

  const resendColumns = [
    {
      key: 'recipients',
      label: 'Recipients who have not yet signed',
      cell: (r: IRecipientBody) => (
        <Typography className="flex items-center max-w-lg">
          {isInActiveSignerGroup(r) ? (
            <Checkbox
              color="primary"
              checked={selectedRecipients.includes(r.id)}
              onClick={() => {
                if (selectedRecipients.includes(r.id)) {
                  setSelectedRecipients(selectedRecipients.filter((id) => id !== r.id));
                } else {
                  setSelectedRecipients([...selectedRecipients, r.id]);
                }
              }}
            />
          ) : (
            <div className="w-[44px]" />
          )}
          <span className="pr-4 font-bold">
            {truncateText(concatObject(r))} {checkIfRecipientIsSender(r) ? '(Me)' : ''}
          </span>
          {truncateText(r.email ?? '', 28)}{' '}
          <IconButton
            data-spec="edit-recipient"
            onClick={() => handleEditRecipient(r)}
            style={{ color: colors.grey[500] }}
          >
            <EditIcon />
          </IconButton>
        </Typography>
      ),
    },
    {
      key: 'signingGroup',
      label: 'Signing group',
      cell: (r: IRecipientBody) => (
        // @ts-ignore
        <Typography className="min-w-32">{`${signingGroupOrder[r.order ?? 0]} Group`}</Typography>
      ),
    },
    {
      key: 'lastSent',
      label: 'Last Sent',
      cell: (r: IRecipientBody & { lastSent: string }) => (
        <Typography className="text-left min-w-40">{r.lastSent ?? 'some date'}</Typography>
      ),
    },
  ];

  if (isFetching || !envelope.id) {
    return (
      <Dialog open>
        <Spinner />
      </Dialog>
    );
  }

  return (
    <Modal
      modalStyles={{
        maxWidth: '880px',
        minWidth: '880px',
        maxHeight: 'none',
        padding: '0 0 20px 0',
        buttonColor: colors.grey[500],
        closeButtonStyle: { height: '40px', width: '40px', marginTop: '8px', marginRight: '16px' },
        actionButtonsStyle: { paddingBottom: '0' },
      }}
      toggleModal={props.close}
      title={<ModalTitle>Remind & Resend</ModalTitle>}
      content={
        <>
          <ModalText>Send a reminder or correct an email address for recipients who have not yet signed.</ModalText>
          {alert.visible ? <Alert id="alert" type={alert.type} message={alert.message} /> : null}
          {recipientToEdit ? (
            <EditRecipientForm
              recipient={recipientToEdit}
              setRecipientToEdit={setRecipientToEdit}
              disabled={disableSenderInput}
              contacts={contacts}
            />
          ) : (
            <Table
              className="pt-8 border-none"
              cellClassName="p-3"
              columns={resendColumns}
              rows={recipientsInProgress}
            />
          )}
        </>
      }
      actionButtons={
        !recipientToEdit && (
          <>
            <ModalCancelButton onClick={props.close} aria-label="cancel" variant="outlined">
              Cancel
            </ModalCancelButton>
            <ModalConfirmationButton
              onClick={() => sendReminders(selectedRecipients)}
              aria-label="send reminder"
              disabled={!selectedRecipients.length}
            >
              {`Send Reminder ${selectedRecipients.length ? `(${selectedRecipients.length})` : ''}`}
            </ModalConfirmationButton>
          </>
        )
      }
    />
  );
}

function formatDate(date?: Date) {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };

  return date.toLocaleDateString('en-US', options);
}
