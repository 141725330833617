import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/styles/useTheme';
import { Theme } from '@material-ui/core/styles';
import { isSkySlopeMobileApp } from '../common/utils';

export const isMobileUserAgent = (navigator: Navigator) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  return isMobile;
};

export const isTabletMediaQuery = (navigator: Navigator) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
  return isTablet;
};

export const isSmallScreenMediaQuery = () => {
  const theme = useTheme<Theme>();
  const isMobile = isMobileUserAgent(navigator);
  return useMediaQuery(theme.breakpoints.down('sm')) && isMobile;
};

export const isMediumScreenMediaQuery = () => {
  const theme = useTheme<Theme>();
  const isMobile = isMobileUserAgent(navigator);
  return useMediaQuery(theme.breakpoints.down('md')) && isMobile;
};

export function isSmallScreenOrMobileApp() {
  const isSmallScreen = isSmallScreenMediaQuery();
  const isMobileApp = isSkySlopeMobileApp();
  return isSmallScreen || isMobileApp;
}
