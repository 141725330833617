import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalCancelButton, ModalConfirmationButton, ModalTitle } from '../../common/Modal';
import { renameEnvelope } from '../../store/envelopeManagement/actions';
import { TextField, Typography } from '@material-ui/core';

interface IProps {
  envelopeId: string;
  envelopeName: string;
  close: () => void;
}

const RenameModal = (props: IProps) => {
  const dispatch = useDispatch();
  const { envelopeId, envelopeName, close } = props;
  const [newEnvelopeName, setNewEnvelopeName] = useState(envelopeName);

  const onClickSave = () => {
    dispatch(renameEnvelope(envelopeId, newEnvelopeName));
    close();
  };

  const title = <ModalTitle>Edit Envelope</ModalTitle>;
  const content = (
    <>
      <Typography variant="overline" className="block w-full pb-4 mt-8">
        ENVELOPE NAME
      </Typography>
      <TextField
        data-spec="edit-envelope-name-input"
        variant="outlined"
        classes={{ root: 'w-full' }}
        inputProps={{ className: 'py-2 px-0' }}
        onChange={(e) => setNewEnvelopeName(e.target.value)}
        value={newEnvelopeName}
      />
    </>
  );
  const actionButtons = (
    <>
      <ModalCancelButton onClick={close} aria-label="cancel edit envelope name">
        Cancel
      </ModalCancelButton>
      <ModalConfirmationButton
        data-spec="edit-envelope-name-save"
        disabled={newEnvelopeName === envelopeName}
        onClick={onClickSave}
        aria-label="confirm edit envelope name"
      >
        Save
      </ModalConfirmationButton>
    </>
  );
  return <Modal toggleModal={close} title={title} content={content} actionButtons={actionButtons} />;
};

export default RenameModal;
