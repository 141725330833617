import React, { forwardRef, useContext } from 'react';
import { Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { colors } from '@skyslope/mache';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/Help';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import classNames from 'classnames';
import { ISettings, TrialExtension } from '../../store/pageFrame/types';
import { IRootState } from '../../store';
import { appMenuLinks, EnvelopeStatuses, ResourceNames } from '../../lib/constants';
import EditEmailModal from '../senderBuild/EditEmailModal';
import { IBlocks, IEmailConfig, IGroups } from '../../store/senderBuild/types';
import * as actions from '../../store/senderBuild/actions';
import { HeaderContext } from '../../context/header-context';
import digiSign from '../../images/digisign.svg';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import validateBlocksAndSendEnvelope from '../../lib/validateBlocksAndSendEnvelope';
import MobileRecipientsHeader from './MobileRecipientsHeader';
import mergeRefs from './merge-refs';
import { LaunchDarklyFlags, withLaunchDarkly } from '../../common/launchDarkly';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';
import { isSkySlopeMobileApp } from '../../common/utils';
import CollapseOnScroll from '../../common/CollapseOnScroll';
import { UserOrigins } from '../../lib/types';
import { ReactComponent as BreezeIcon } from '../../images/breeze_icon.svg';
import { ReactComponent as FormsIcon } from '../../images/forms_icon.svg';
import { ReactComponent as SkyslopeIcon } from '../../images/skyslope_icon.svg';
import TrialPeriodBanner from './TrialBanner';
import { handleAppLogout } from '../../lib/utils';
import { twMerge } from 'tailwind-merge';

const useStyles = makeStyles((theme: Theme) => ({
  headerMargin: {
    width: '100%',
    height: '80px',
  },
  titleRoot: {
    paddingLeft: '32px',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
  },
  listItemText: {
    '& span': {
      fontSize: '19px',
      fontWeight: 800,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '16px 32px',
    borderTop: `1px solid ${colors.grey[400]}`,
    color: `${colors.grey[800]}`,
  },
  listItemRoot: {
    '& hover': {
      cursor: 'pointer',
    },
  },
  activeSection: {
    backgroundColor: `${colors.blue[50]}`,
  },
  menuItem: {
    height: '40px',
    display: 'flex',
    paddingRight: '16px',
  },
  menuItemRoom: {
    padding: '0 16px 0 0',
    minHeight: '0',
  },
  itemContainer: {
    marginLeft: '16px',
    paddingLeft: '16px',
    borderRadius: '3px',
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
  },
  activeText: {
    '& span': {
      fontWeight: 800,
      color: colors.blue[800],
    },
  },
}));

interface IState {
  settings: ISettings;
  resource: ResourceNames;
  emailConfig: IEmailConfig;
  envelopeId: string;
  envelopeStatus: string;
  blocks: IBlocks;
  blockGroups: IGroups;
  id: string;
  saving: boolean;
  readyToSendModalOpen: boolean;
  envelopeSent: boolean;
  zoom: number;
}

const headerSelector = (state: IRootState) => ({
  settings: state.pageFrame.settings,
  resource: state.senderBuild.resource,
  emailConfig: state.senderBuild.emailConfig,
  envelopeId: state.senderBuild.id,
  envelopeStatus: state.senderBuild.status,
  blocks: state.senderBuild.blocks,
  blockGroups: state.senderBuild.groups,
  id: state.senderBuild.id,
  saving: state.senderBuild.saving,
  readyToSendModalOpen: state.senderBuild.readyToSendModalOpen,
  envelopeSent: state.senderBuild.done,
  zoom: state.senderBuild.zoom,
});

const Header = (props: {
  setHeaderSize: (size: number) => void;
  redirectHeader?: boolean;
  flags: LaunchDarklyFlags;
  showTrialBanner: boolean;
  isTrialExpired: boolean;
  daysLeft: number;
  handleDismissBanner: () => void;
  isEligibleForExtension: boolean;
  latestTrialExtension?: TrialExtension;
}) => {
  const dispatch = useDispatch();
  const headerRef = React.useRef<HTMLElement>(null);
  const state: IState = useShallowSelector(headerSelector);
  const { emailConfig, envelopeId, blocks, blockGroups } = state;
  const isFormsUser = state.settings?.account.userOrigin === UserOrigins.Forms;
  const areTemplatesEnabled = props.flags && props.flags['enable-templates-access'];
  const hideUIItems = props.flags && props.flags['hide-build-ui-elements'];
  const headerMarginRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [appMenuOpen, setAppMenuOpen] = React.useState(false);
  const [helpMenuOpen, setHelpMenuOpen] = React.useState(false);
  const [showEditEmailModal, setShowEditEmailModal] = React.useState(false);
  const { content } = useContext(HeaderContext);
  const open = Boolean(anchorEl);
  const classes = useStyles(props);
  const history = useHistory();
  const isSmallScreen = isSmallScreenMediaQuery();
  const envelopeSent = state.envelopeStatus.length && state.envelopeStatus === EnvelopeStatuses.SENT;
  const isEnvelopeResouce = state.resource === ResourceNames.ENVELOPE;
  // We set the envelope id after the fetch call on the build ui
  const isBuildPage = !!state.id && window.location.pathname.endsWith(state.id);

  const isMobileApp = isSkySlopeMobileApp();

  React.useEffect(() => {
    if (!isMobileApp) {
      const headerHeight = headerRef.current!.clientHeight;
      headerMarginRef.current!.style.height = `${headerHeight}px`;
      props.setHeaderSize(headerHeight);
    }
  });

  const toggleModal = () => {
    const emailSubject = emailConfig.subject?.trim() || '';
    const emailBody = emailConfig.body?.trim() || '';
    if (hideUIItems) {
      const updatedBlocksAndGroups = validateBlocksAndSendEnvelope(blocks, blockGroups, true, state.zoom);
      if (updatedBlocksAndGroups) {
        dispatch(
          actions.validateBlocksBeforeSending(
            updatedBlocksAndGroups.updatedBlocks,
            updatedBlocksAndGroups.updatedGroups,
            { subject: emailSubject || '', body: emailBody },
            true
          )
        );
      } else {
        dispatch(actions.updateEmailConfig(envelopeId, { subject: emailSubject, body: emailBody }, true));
      }
    } else {
      setShowEditEmailModal(!showEditEmailModal);
    }
  };

  const handleMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleAppClick = (app: 'forms' | 'skyslope' | 'breeze') => {
    let currentEnv = window.location.hostname.split('-')[0];
    currentEnv = currentEnv === 'localhost' ? 'dev' : currentEnv;
    const url = appMenuLinks[`${currentEnv}-${app}`];
    window.location.href = url;
  };

  const handleHelpClick = () => {
    setHelpMenuOpen(!helpMenuOpen);
  };

  const handleAppItemClick = () => {
    setAppMenuOpen(!appMenuOpen);
  };

  const handleMenuBackButton = () => {
    helpMenuOpen ? setHelpMenuOpen(false) : setAppMenuOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const listItems = () => {
    if (appMenuOpen) {
      return (
        <>
          <ListItem disableGutters>
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemText classes={{ root: classes.listItemText }} primary="Apps" />
            </span>
          </ListItem>
          <MenuItem
            classes={{
              root: classes.menuItemRoom,
            }}
            className={classes.menuItem}
            disableGutters
            onClick={() => handleAppClick('skyslope')}
          >
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemIcon>
                <SkyslopeIcon className="mr-4 text-blue-800 h-6 w-6" />{' '}
              </ListItemIcon>
              <ListItemText primary="Skyslope" />
            </span>
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.menuItemRoom,
            }}
            className={classes.menuItem}
            disableGutters
            onClick={() => handleAppClick('forms')}
          >
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemIcon>
                <FormsIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
              </ListItemIcon>
              <ListItemText primary="Forms" />
            </span>
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.menuItemRoom,
            }}
            className={classes.menuItem}
            disableGutters
            onClick={() => handleAppClick('breeze')}
          >
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemIcon>
                <BreezeIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
              </ListItemIcon>
              <ListItemText primary="Breeze" />
            </span>
          </MenuItem>
        </>
      );
    }
    if (helpMenuOpen) {
      return (
        <>
          <ListItem disableGutters>
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemText classes={{ root: classes.listItemText }} primary="Help" />
            </span>
          </ListItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open('https://skyslope.com/privacy-policy/', '_blank')}
            disableGutters
            classes={{
              root: classes.menuItemRoom,
            }}
          >
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemText primary="Privacy Policy" />
            </span>
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.menuItemRoom,
            }}
            className={classes.menuItem}
            onClick={() => window.open('https://skyslope.com/terms-conditions/', '_blank')}
            disableGutters
          >
            <span
              className={classNames({
                [classes.itemContainer]: true,
              })}
            >
              <ListItemText primary="Terms of Use" />
            </span>
          </MenuItem>
        </>
      );
    }
    return (
      <>
        {!isFormsUser && (
          <>
            <MenuItem
              onClick={() => (window.location.pathname = '/envelopes')}
              disableGutters
              className={classes.menuItem}
              classes={{
                root: classes.menuItemRoom,
              }}
            >
              <span
                style={{ visibility: isEnvelopeResouce ? 'visible' : 'hidden' }}
                className="h-20 w-1 rounded-r-sm rounded-l-none bg-blue-800 absolute"
              />
              <span
                className={classNames({
                  [classes.itemContainer]: true,
                  [classes.activeSection]: isEnvelopeResouce,
                })}
              >
                <ListItemIcon>
                  <MailIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
                </ListItemIcon>
                <ListItemText className={isEnvelopeResouce ? classes.activeText : ''} primary="Envelopes" />
              </span>
            </MenuItem>
            {areTemplatesEnabled && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => (window.location.pathname = '/templates')}
                disableGutters
                classes={{
                  root: classes.menuItemRoom,
                }}
              >
                <span
                  style={{ visibility: !isEnvelopeResouce ? 'visible' : 'hidden' }}
                  className={classNames({
                    ['h-20 w-1 rounded-r-sm rounded-l-none bg-blue-800 absolute']: true,
                  })}
                />
                <span
                  className={classNames({
                    [classes.itemContainer]: true,
                    [classes.activeSection]: !isEnvelopeResouce,
                  })}
                >
                  <ListItemIcon>
                    <DescriptionIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
                  </ListItemIcon>
                  <ListItemText className={!isEnvelopeResouce ? classes.activeText : ''} primary="Templates" />
                </span>
              </MenuItem>
            )}
          </>
        )}
        <MenuItem
          className={classes.menuItem}
          disableGutters
          classes={{
            root: classes.menuItemRoom,
          }}
          onClick={handleAppItemClick}
        >
          <span className={classes.itemContainer}>
            <ListItemIcon>
              <AppsIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          disableGutters
          classes={{
            root: classes.menuItemRoom,
          }}
          onClick={handleHelpClick}
        >
          <span className={classes.itemContainer}>
            <ListItemIcon>
              <HelpIcon className={'mr-4 text-blue-800 h-6 w-6'} />{' '}
            </ListItemIcon>
            <ListItemText primary="Help" />
          </span>
        </MenuItem>
      </>
    );
  };

  const handleEditEmailMessage = () => {
    handleClose();
    setShowEditEmailModal(true);
  };

  const exit = () => {
    if (isFormsUser) {
      window.history.back();
    } else {
      history.push(isEnvelopeResouce ? '/envelopes' : '/templates');
    }
  };

  const moreMenuItems = () => {
    return (
      <span>
        {envelopeSent ? '' : <MenuItem onClick={handleEditEmailMessage}>Edit Message</MenuItem>}
        {isFormsUser ? <MenuItem onClick={exit}>Save & Exit</MenuItem> : <MenuItem onClick={exit}>Exit</MenuItem>}
      </span>
    );
  };

  const headerContent = () => {
    const headerClass = isSmallScreen ? 'w-full h-[135px]' : classes.headerMargin;
    return (
      <div className={headerClass} ref={headerMarginRef}>
        {!isMobileApp && (
          <>
            <CollapseOnScroll disableCollapse={!isSmallScreen || (isSmallScreen && props.showTrialBanner)}>
              <div
                className={classes.headerMargin}
                ref={headerMarginRef}
                style={{ height: isSmallScreen ? '64px' : 'auto' }}
              />
            </CollapseOnScroll>
            <CollapseOnScroll disableCollapse={!isSmallScreen || (isSmallScreen && props.showTrialBanner)}>
              <MainHeader
                classes={classes}
                isSmallScreen={isSmallScreen}
                headerMarginRef={headerMarginRef}
                headerRef={headerRef}
                handleMobileMenu={handleMobileMenu}
                envelopeSent={envelopeSent}
                isFormsUser={isFormsUser}
                handleClick={handleClick}
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={open}
                toggleModal={toggleModal}
                setMenuOpen={setMenuOpen}
                setHelpMenuOpen={setHelpMenuOpen}
                setAppMenuOpen={setAppMenuOpen}
                helpMenuOpen={helpMenuOpen}
                menuOpen={menuOpen}
                showEditEmailModal={showEditEmailModal}
                moreMenuItems={moreMenuItems}
                handleMenuBackButton={handleMenuBackButton}
                appMenuOpen={appMenuOpen}
                listItems={listItems}
                content={content}
                redirectHeader={props.redirectHeader}
                showTrialBanner={props.showTrialBanner}
                isTrialExpired={props.isTrialExpired}
                daysLeft={props.daysLeft}
                handleDismissBanner={props.handleDismissBanner}
                isMobileApp={isMobileApp}
                isEligibleForExtension={props.isEligibleForExtension}
                latestTrialExtension={props.latestTrialExtension}
              />
            </CollapseOnScroll>
          </>
        )}
        {(isSmallScreen || isMobileApp) &&
        !state.readyToSendModalOpen &&
        isBuildPage &&
        !state.envelopeSent &&
        !state.saving ? (
          <MobileRecipientsHeader />
        ) : (
          ''
        )}
      </div>
    );
  };

  return headerContent();
};

const MainHeader = forwardRef((props: any, ref: React.RefObject<any>) => {
  const {
    classes,
    isSmallScreen,
    headerRef,
    handleMobileMenu,
    envelopeSent,
    isFormsUser,
    handleClick,
    anchorEl,
    handleClose,
    open,
    toggleModal,
    setMenuOpen,
    setHelpMenuOpen,
    setAppMenuOpen,
    helpMenuOpen,
    menuOpen,
    showEditEmailModal,
    moreMenuItems,
    handleMenuBackButton,
    appMenuOpen,
    listItems,
    content,
    showTrialBanner,
    isTrialExpired,
    daysLeft,
    handleDismissBanner,
    isMobileApp,
    isEligibleForExtension,
    latestTrialExtension,
  } = props;

  const history = useHistory();
  const isTemplatePage = /\/templates/.test(window.location.toString());

  const handleRedirect = () => {
    if (props.redirectHeader) {
      history.push(isTemplatePage ? '/templates' : '/envelopes');
    }
  };
  // temporary fix for mobile header for subscriptions page
  const isSubscriptionsPage = window.location.pathname.endsWith('subscriptions');
  return (
    <AppBar
      position="fixed"
      className={twMerge('bg-white shadow h-auto transition-all', isSmallScreen && 'h-24 flex justify-around')}
      ref={mergeRefs(headerRef, ref)}
    >
      <div className="flex flex-row justify-between sm:py-3 sm:px-6">
        <div className={isSmallScreen ? 'flex w-full flex-col' : 'flex w-full items-center'}>
          <div className={isSmallScreen ? 'flex justify-between px-2.5' : ''} id="logoContainer">
            {isSmallScreen && (
              <>
                <IconButton onClick={() => handleMobileMenu()}>
                  <MenuIcon />
                </IconButton>{' '}
              </>
            )}
            <img
              src={digiSign}
              alt="logo"
              className={isSmallScreen ? 'w-[120px] max-w-[120px]' : 'max-w-[120px] mb-4 w-[120px]'}
              onClick={() => handleRedirect()}
              style={{
                cursor: props.redirectHeader ? 'pointer' : undefined,
              }}
            />
            {isSmallScreen && (
              <>
                <IconButton
                  style={{ visibility: !!envelopeSent || isFormsUser ? 'hidden' : 'unset' }}
                  onClick={handleClick}
                >
                  {!isSubscriptionsPage && <MoreHorizIcon />}
                </IconButton>
              </>
            )}
          </div>
          {isSubscriptionsPage ? (
            <></>
          ) : (
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {moreMenuItems()}
            </Menu>
          )}
          {showEditEmailModal && (
            <EditEmailModal
              fullScreen={isSmallScreen}
              toggleModal={toggleModal}
              subject="Ready to send?"
              action="Send"
              send
            />
          )}
          <Dialog
            classes={{
              root: 'flex',
              paper: 'm-0 h-full w-full',
              paperScrollPaper: 'max-h-none',
              scrollPaper: 'w-full max-w-[320px]',
            }}
            open={menuOpen}
            scroll="paper"
            onClose={() => {
              setMenuOpen(false);
              setHelpMenuOpen(false);
              setAppMenuOpen(false);
            }}
          >
            <DialogTitle classes={{ root: classes.titleRoot }}>
              {appMenuOpen || helpMenuOpen ? (
                <ArrowBackIcon onClick={handleMenuBackButton} />
              ) : (
                <img src={digiSign} alt="logo" className={'w-[120px] max-w-[120px]'} />
              )}{' '}
              <IconButton
                style={{ zIndex: 1500, color: `${colors.grey[500]}` }}
                onClick={() => {
                  setMenuOpen(false);
                  setAppMenuOpen(false);
                  setHelpMenuOpen(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <List>{listItems()}</List>
            </DialogContent>
            <DialogActions onClick={() => handleAppLogout()} classes={{ root: classes.actions }}>
              <ExitToAppIcon style={{ paddingRight: '10px' }} /> Logout
            </DialogActions>
          </Dialog>
          {content ? <div className={'ml-8 w-full'}>{content}</div> : null}
        </div>
      </div>
      {showTrialBanner && !isMobileApp && !isSubscriptionsPage && (
        <TrialPeriodBanner
          expired={isTrialExpired}
          daysLeft={daysLeft}
          dismissBanner={() => handleDismissBanner()}
          isEligibleForExtension={isEligibleForExtension}
          latestTrialExtension={latestTrialExtension}
        />
      )}
    </AppBar>
  );
});

export default withLaunchDarkly(Header);
