import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Button } from '@material-ui/core';
import produce from 'immer';
import { useParams } from 'react-router-dom';
import { CheckCircle } from '@material-ui/icons';
import Table from '../common/Table';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import {
  IAuditHistoryBody,
  IEnvelopeHistoryBody,
  IEventData,
  IGroupDetails,
  IRecipientAddOrDeleteEventData,
  IRecipientBody,
} from '../../store/envelopeHistory/types';
import { IRootState } from '../../store';
import { getTimezoneCode, concatStrings, bold, concatObject, boldAll } from '../../lib/utils';
import { eventNames, emailTypes } from '../../lib/constants';
import * as auditHistoryActions from '../../store/envelopeHistory/actions';
import { hideAlert } from '../../store/pageFrame/actions';
import { sendEmail } from '../../store/envelopeHistory/actions';

dayjs.extend(advancedFormat);

interface IState {
  isFetching: boolean;
  auditHistory: IAuditHistoryBody[];
  envelope: IEnvelopeHistoryBody;
  recipientsWhoHaveNotViewed: IRecipientBody[];
}

const selector = (state: IRootState) => ({
  isFetching: state.envelopeHistory.isFetching,
  auditHistory: state.envelopeHistory.auditHistory,
  envelope: state.envelopeHistory.envelope,
  recipientsWhoHaveNotViewed: state.envelopeHistory.recipientsWhoHaveNotViewed,
});

const emailNames = {
  [emailTypes.ReviewAndSign]: 'Resent envelope to ',
  [emailTypes.Completed]: 'Envelope Completed email sent to ',
  [emailTypes.Canceled]: 'Envelope Canceled email sent to ',
};

type RecipientInfoMap = { [id: string]: IRecipientBody };
type AuditEventWithRecipientInfo = IAuditHistoryBody & { recipientInfo: RecipientInfoMap };

function getRecipientActionType(event: IAuditHistoryBody) {
  if ([eventNames.SignerAdded, eventNames.CarbonCopyAdded].includes(event.eventName)) {
    return 'add';
  }
  if ([eventNames.SignerUpdated, eventNames.CarbonCopyUpdated].includes(event.eventName)) {
    return 'update';
  }
  if ([eventNames.SignerDeleted, eventNames.CarbonCopyDeleted].includes(event.eventName)) {
    return 'delete';
  }
  return null;
}

function getEventsWithRecipientInfo(events: IAuditHistoryBody[], recipientInfoMap: RecipientInfoMap) {
  let currentStateOfRecipients = { ...recipientInfoMap };

  return events
    .filter(
      (e) =>
        ![
          eventNames.SignerDeleted,
          eventNames.CarbonCopyDeleted,
          eventNames.SignerAdded,
          eventNames.CarbonCopyAdded,
        ].includes(e.eventName)
    )
    .map((event) => {
      const actionType = getRecipientActionType(event);
      const eventData = event.eventData as IEventData;

      currentStateOfRecipients = produce(currentStateOfRecipients, (draft) => {
        if (actionType === 'update' && eventData.recipientId) {
          draft[eventData.recipientId] = {
            ...draft[eventData.recipientId],
            firstName: eventData.originalFirstName,
            lastName: eventData.originalLastName,
            email: eventData.originalEmail,
          };
        }
      });

      return { ...event, recipientInfo: currentStateOfRecipients };
    });
}

export default function ActivityLog() {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { envelope, auditHistory, recipientsWhoHaveNotViewed }: IState = useShallowSelector(selector);
  const { senderFirstName, senderMiddleName, senderLastName } = envelope;
  const senderName = concatStrings(senderFirstName, senderMiddleName, senderLastName);
  const recipientInfo = envelope.recipients.reduce((acc, r) => ({ ...acc, [r.id]: r }), {});

  function sendReminder(recipientId: string) {
    dispatch(hideAlert());
    dispatch(
      sendEmail(envelope.id, {
        recipient: { recipientId },
        emailType: 'ReviewAndSign',
        status: 'Sent',
        isResend: 'true',
      })
    );
    dispatch(auditHistoryActions.fetch(params.id));
  }

  function handleEventType(event: AuditEventWithRecipientInfo) {
    const eventData = event.eventData as IEventData;
    const originalRecipientName = concatStrings(
      eventData.originalFirstName,
      eventData.originalLastName,
      `(${eventData.originalEmail})`
    );
    const updatedRecipientName = concatStrings(
      eventData.updatedFirstName,
      eventData.updatedLastName,
      `(${eventData.updatedEmail!})`
    );

    let userName = event.userName?.trim() || senderName;
    const isDelegate = event.userType === 'sender' && event.userId !== envelope.senderId;
    const canceledBySystem = event.userType === 'system';
    if (isDelegate) {
      userName = `${userName} on behalf of ${senderName}`;
    }
    const allRecipients = Object.values(event.recipientInfo);

    const recipientName = concatObject(
      allRecipients.find((r) => (r.id === event.userId && !eventData.recipientId) || r.id === eventData.recipientId)
    );

    const recipientWhoHasNotViewedName = concatObject(
      recipientsWhoHaveNotViewed?.find(
        (r) => (r.id === event.userId && !eventData.recipientId) || r.id === eventData.recipientId
      )
    );

    const oldEnvelopeName = event.eventName === eventNames.EnvelopeNameUpdated ? eventData.oldEnvelopeName : '';
    const tableCellContent = {
      [eventNames.EnvelopeCreated]: <>Created by {bold(userName)}</>,
      [eventNames.EnvelopeCorrecting]: <>Envelope being corrected by {bold(userName)}</>,
      [eventNames.EnvelopeViewed]: <>Viewed by {bold(recipientName)}</>,
      [eventNames.EnvelopeSigned]: <>Signed by {bold(recipientName)}</>,
      [eventNames.EnvelopeCanceled]: canceledBySystem ? 'Envelope canceled by system' : 'Envelope Canceled',
      [eventNames.EnvelopeCompleted]: 'Envelope Completed',
      [eventNames.SignatureAccepted]: <>{bold(recipientName)} has adopted their electronic signature</>,
      [eventNames.WaitingFor]: <>Waiting for {bold(recipientWhoHasNotViewedName)} to view documents</>,
      [eventNames.EnvelopeDownloaded]: (
        <>
          Envelope Downloaded by{' '}
          {allRecipients.find((r) => r.id === event.userId) ? bold(recipientName) : bold(userName)}
        </>
      ),
      [eventNames.SignerUpdated]: (
        <>
          Details for {bold(originalRecipientName)} updated to {bold(updatedRecipientName)}
        </>
      ),
      [eventNames.CarbonCopyUpdated]: (
        <>
          Details for {bold(originalRecipientName)} updated to {bold(updatedRecipientName)}
        </>
      ),
      [eventNames.ESignDisclosureAccepted]: (
        <>{bold(recipientName)} has agreed to the ESIGN Consumer Disclosure and Consent</>
      ),
      [eventNames.EnvelopeSent]: (
        <>
          Sent to {boldAll(allRecipients)} by {bold(userName)}
        </>
      ),
      [eventNames.NotificationRequested]: (
        <>
          {emailNames[eventData.emailType as keyof typeof emailNames]}
          {allRecipients.find((r) => eventData.recipientId != null && r.id === eventData.recipientId)
            ? bold(recipientName)
            : bold(userName)}
        </>
      ),
      [eventNames.EnvelopeNameUpdated]: oldEnvelopeName ? (
        <>
          Envelope name was updated from {bold(oldEnvelopeName)} to {bold(eventData.newEnvelopeName)} by{' '}
          {bold(userName)}
        </>
      ) : (
        <>
          Envelope name was updated to {bold(eventData.newEnvelopeName)} by {bold(userName)}
        </>
      ),
    };

    return tableCellContent[event.eventName];
  }

  const activityLogColumns = [
    {
      key: 'eventName',
      label: 'Activity',
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className="max-w-xl break-words" data-spec={e.eventName}>
          {handleEventType(e)}
        </Typography>
      ),
    },
    {
      key: 'date',
      label: 'Date',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className="whitespace-nowrap">
          {e.eventName !== eventNames.WaitingFor ? dayjs(e.date).format('MMMM Do, YYYY') : ''}
        </Typography>
      ),
    },
    {
      key: 'time',
      label: 'Time',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <Typography className="whitespace-nowrap">
          {e.eventName !== eventNames.WaitingFor ? dayjs(e.time).format('hh:mm A').concat(' ', getTimezoneCode()) : ''}
        </Typography>
      ),
    },
    {
      key: 'action',
      label: 'Action',
      align: 'left' as const,
      cell: (e: AuditEventWithRecipientInfo) => (
        <ActivityLogResendButton envelope={envelope} event={e} onClick={sendReminder} />
      ),
    },
  ];

  return (
    <div className="pt-16 pb-8">
      <Typography variant="overline">Activity Log</Typography>
      <Paper className="mt-8" elevation={0}>
        <Table
          activityLog
          columns={activityLogColumns}
          rows={getEventsWithRecipientInfo(auditHistory, recipientInfo)}
        />
      </Paper>
    </div>
  );
}

function ActivityLogResendButton({
  envelope,
  event,
  onClick,
}: {
  envelope: IEnvelopeHistoryBody;
  event: IAuditHistoryBody;
  onClick: (arg: string) => void;
}) {
  const [isResendClicked, setIsResendClicked] = React.useState(false);

  function handleClick(recipientId: string) {
    setIsResendClicked(true);
    onClick(recipientId);
  }

  const group = envelope.signerDetails?.details?.find(
    // @ts-ignore
    (d: IGroupDetails) => d.value.signerId === event.eventData.recipientId
  );
  const isInActiveSignerGroup = group?.value.signingGroupId === envelope?.signerDetails?.activeGroup;

  return (
    <>
      {isInActiveSignerGroup && event.eventName === eventNames.WaitingFor && (
        <Button
          data-spec="resend-button"
          variant={isResendClicked ? 'text' : 'outlined'}
          color="primary"
          disabled={isResendClicked}
          // @ts-ignore
          onClick={() => handleClick(event.eventData.recipientId)}
          startIcon={isResendClicked ? <CheckCircle /> : null}
        >
          {isResendClicked ? 'Reminder Sent' : 'Remind & Resend'}
        </Button>
      )}
    </>
  );
}
