import React, { useRef, useEffect } from 'react';
import { colors } from '@skyslope/mache';
import { useAutomaticFontSizing } from '../../lib/useAutomaticFontSizing';
import { useDispatch } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IBlock } from '../../store/senderBuild/types';

interface IProps {
  block: IBlock;
  zoom: number;
  documentId: string;
  pageIndex: number;
  blockIndex: number;
}

export function TextBlockEditing(props: IProps) {
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { fontSize, value, setValue } = useAutomaticFontSizing({
    height: props.block.height!,
    width: props.block.width!,
    zoom: props.zoom,
    initialFontSize: props.block.fontSize!,
    initialValue: props.block.value as string,
  });

  const debouncedFontSize = fontSize;
  const debouncedValue = value;

  useEffect(() => {
    textareaRef.current?.focus();
    return () => {
      if (!props.block.isCreating) {
        dispatch(actions.disableTextEditing());
      }
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (value !== props.block.value && !props.block.isCreating) {
      dispatch(actions.editBlock(props.documentId, props.pageIndex, props.blockIndex, { fontSize, value }));
    }
  }, [debouncedValue, debouncedFontSize]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  function handleOnFocus() {
    textareaRef.current?.focus();
    if (textareaRef.current) {
      const length = textareaRef.current?.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }

  return (
    <div
      className={`relative block-${props.block.blockId} break-word h-full w-full px-1 focus:outline-none`}
      onFocus={handleOnFocus}
      id={`textBlock-${props.block.blockId}`}
      style={{
        fontSize: debouncedFontSize * props.zoom,
      }}
    >
      <textarea
        ref={textareaRef}
        spellCheck={false}
        value={value}
        onChange={handleTextChange}
        className="w-full h-full p-0 overflow-hidden font-bold bg-transparent border-none outline-none resize-none focus:outline-none leading-[1]"
        style={{
          fontSize: debouncedFontSize * props.zoom,
          color: colors.grey[900],
          fontFamily: 'courier',
        }}
      />
    </div>
  );
}
