import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { makeStyles, Theme } from '@material-ui/core/styles';
import fitty from 'fitty';
import { colors } from '@skyslope/mache';
import { getValueBasedOnBlockType } from '../../lib/utils';
import { IBlock, ISigner, IStrike, StrikeType } from '../../store/senderBuild/types';
import { BLOCK_TYPE_KEYS, StrikeColors, STRIKE_LINE_WIDTH } from '../../lib/constants';
import { ReactComponent as CheckIcon } from '../../images/check.svg';
import { useAutomaticFontSizing } from '../../lib/useAutomaticFontSizing';
import * as actions from '../../store/senderBuild/actions';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import { TextBlockEditing } from './TextBlockEditing';
import { IRootState } from '../../store';
import { isSmallScreenOrMobileApp } from '../../lib/isSmallScreen';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  innerBlock: {
    borderRadius: '1px',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    color: colors.grey[900],
    wordBreak: 'break-word',
    fontFamily: 'courier',
    fontWeight: 'bold',
    '&[contenteditable]': {
      '-webkit-user-select': 'text',
      'user-select': 'text',
      '-moz-user-select': 'text !important',
      '-khtml-user-select': 'text',
      '-o-user-select': 'text',
    },
    ':before': {
      position: 'absolute',
    },
  },
  checkboxInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '1px solid black',
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  specific: {
    fontFamily: 'Damion !important',
    lineHeight: 0.65,
    fontWeight: 'unset',
  },
  preventOverflow: {
    height: '100%',
    overflow: 'hidden',
    outline: '0px solid transparent',
  },
  editingMode: {
    backgroundColor: '#FFF',
  },
}));

interface IProps {
  blockType: any;
  block: IBlock;
  zoom: number;
  color: string;
  signer?: ISigner;
  isSelected?: boolean;
  isTextBlockEditingEnabled?: boolean;
  documentId?: string;
  blockIndex?: number;
}

const selector = (state: IRootState) => ({
  dateFormat: state.pageFrame.settings?.user.dateFormat?.preference,
});

export default (props: IProps) => {
  const isMobile = isSmallScreenOrMobileApp();
  const { block, blockType, zoom, signer, documentId, blockIndex, isTextBlockEditingEnabled } = props;
  const dispatch = useDispatch();
  const { fontSize } = useAutomaticFontSizing({
    height: props.block.height!,
    width: props.block.width!,
    zoom: props.zoom,
    initialFontSize: props.block.fontSize!,
    initialValue: props.block.value as string,
  });
  const debouncedFontsize = fontSize;
  const firstRender = React.useRef(true);
  const isTextBoxEditingActive = isTextBlockEditingEnabled && block.blockType === BLOCK_TYPE_KEYS.TEXT;
  const { dateFormat } = useShallowSelector(selector);

  React.useEffect(() => {
    if (block.blockType === BLOCK_TYPE_KEYS.TEXT) {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      // This will get called when this is a child of BlockStatic upon load to correct block sizing
      // from templates or Forms. The fontSize gets calculated based on the bounding box above, and
      // if it does not match the size we have saved we update the saved font size to match this.
      if (block.fontSize !== fontSize && !block.isCreating && !documentId && block.documentId) {
        dispatch(actions.editBlock(block.documentId, block.pageNumber, blockIndex, { fontSize }));
        return;
      }
      // This will only run if the block is being edited, documentId does not get passed down from
      // BlockStatic
      if (block.fontSize !== fontSize && documentId !== undefined && !block.isCreating) {
        dispatch(actions.editBlock(documentId, block.pageNumber, blockIndex, { fontSize }));
      }
    }
  }, [debouncedFontsize]);

  const valueRef = React.useRef(null);
  const classes = useStyles();
  const useFitty =
    blockType.key === BLOCK_TYPE_KEYS.SIGNATURE ||
    blockType.key === BLOCK_TYPE_KEYS.FULL_NAME ||
    blockType.key === BLOCK_TYPE_KEYS.INITIALS ||
    blockType.key === BLOCK_TYPE_KEYS.DATE ||
    blockType.key === BLOCK_TYPE_KEYS.TIME;

  const useSpecificFont = blockType.key === BLOCK_TYPE_KEYS.SIGNATURE || blockType.key === BLOCK_TYPE_KEYS.INITIALS;
  const preventOverflow = blockType.key === BLOCK_TYPE_KEYS.TEXT;
  const isCheckbox = blockType.key === BLOCK_TYPE_KEYS.CHECKBOX;
  const isStrike = blockType.key === BLOCK_TYPE_KEYS.STRIKE;

  React.useEffect(() => {
    if (useFitty) {
      fitty(valueRef.current!, {
        multiLine: false,
        minSize: 4,
        maxSize: block.height! * zoom,
      });
    }
  }, [block.blockId, block.width, block.height, zoom]);

  const blockValue = getValueBasedOnBlockType(blockType.key, signer!, dateFormat, block.value);
  let blockFontSize = block.height! * zoom * 0.75;
  if (blockType.key === BLOCK_TYPE_KEYS.TEXT) {
    blockFontSize = block.fontSize! * zoom;
  }

  if (isCheckbox) {
    return (
      <div
        className={classes.wrapper}
        style={{ backgroundColor: block.groupId && props.isSelected ? '#FFFFFF' : undefined }}
      >
        <div
          ref={valueRef}
          className={`${classes.innerBlock} ${classes.checkboxInner}`}
          style={{
            boxShadow: `0 0 0 1px ${props.color}`,
            borderRadius: isMobile ? 0 : 3,
          }}
        >
          {block.value ? <CheckIcon height={blockFontSize} width={blockFontSize} /> : ''}
        </div>
      </div>
    );
  }

  if (isStrike) {
    const strikeColor = block.color || StrikeColors.Black;
    return (
      <svg
        overflow="visible"
        viewBox="0 0 100 100"
        id="svg"
        style={{ position: 'absolute', height: '100%', width: '100%' }}
        preserveAspectRatio="none"
      >
        {(block as IStrike).strikeType === StrikeType.Down && (
          <line
            strokeWidth={`${STRIKE_LINE_WIDTH * props.zoom}px`}
            x1="0"
            y1="0"
            x2="100"
            y2="100"
            stroke={strikeColor}
            vectorEffect="non-scaling-stroke"
          />
        )}
        {(block as IStrike).strikeType === StrikeType.Up && (
          <line
            strokeWidth={`${STRIKE_LINE_WIDTH * props.zoom}px`}
            x1="100"
            y1="0"
            x2="0"
            y2="100"
            stroke={strikeColor}
            vectorEffect="non-scaling-stroke"
          />
        )}
        {!(block as IStrike).strikeType && (
          <line
            strokeWidth={`${STRIKE_LINE_WIDTH * props.zoom}px`}
            x1="0"
            y1="0"
            x2="100%"
            y2="0"
            stroke={strikeColor}
            vectorEffect="non-scaling-stroke"
          />
        )}
      </svg>
    );
  }

  const content = isTextBoxEditingActive ? (
    <TextBlockEditing
      block={props.block}
      zoom={props.zoom}
      documentId={props.documentId!}
      pageIndex={props.block.pageNumber}
      blockIndex={props.blockIndex!}
    />
  ) : (
    <div
      ref={valueRef}
      className={` ${classes.innerBlock} ${useSpecificFont ? classes.specific : ''} ${
        preventOverflow ? classes.preventOverflow : ''
      }`}
      style={{ fontSize: !useFitty ? blockFontSize : undefined, padding: !useSpecificFont ? '0 4px' : undefined }}
    >
      {blockValue}
    </div>
  );
  const wrapperClass = isTextBoxEditingActive ? `${classes.wrapper} ${classes.editingMode}` : classes.wrapper;
  return (
    <div className={wrapperClass} style={{ boxShadow: `0 0 0 1px ${props.color}`, zIndex: 12 }}>
      {content}
    </div>
  );
};
