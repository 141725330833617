import React from 'react';
import cx from 'classnames';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Theme } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { HandleStyles, Rnd } from 'react-rnd';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IBlock, ISigner, IStrike, StrikeType } from '../../store/senderBuild/types';
import { ARROW_KEY_MOVEMENT_INCREMENT, BLOCK_TYPE_KEYS, BLOCK_TYPES } from '../../lib/constants';
import BlockTag from './BlockTag';
import BlockInner from './BlockInner';
import { hex2rgba } from '../../lib/utils';
import { colors } from '@skyslope/mache';
import { makeStyles } from '@material-ui/styles';
import { IRootState } from '../../store';
import { isSmallScreenOrMobileApp } from '../../lib/isSmallScreen';
import { twMerge } from 'tailwind-merge';

const useStyles = makeStyles((theme: Theme) => ({
  rnd: {
    zIndex: 5,
  },
  block: {
    color: colors.grey[900],
    display: 'flex',
    height: '100%',
    width: '100%',
    userSelect: 'none',
    boxSizing: 'content-box',
    outline: '2px solid transparent', // fixes box-shadow of children being clipped
  },
  resizeWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  resize: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    border: '2px solid',
    position: 'absolute',
    backgroundColor: 'white',
  },
  resizeTopLeft: {
    top: -5,
    left: -5,
  },
  resizeTopRight: {
    top: -5,
    right: -5,
  },
  resizeBottomLeft: {
    bottom: -5,
    left: -5,
  },
  resizeBottomRight: {
    bottom: -5,
    right: -5,
  },
  ignoreReactRnd: {},
  groupButton: {
    height: 24,
    width: 24,
    backgroundColor: colors.blue[800],
    cursor: 'pointer',
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 'calc(50% - 12px)',
    color: '#ffffff',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedCheckbox: {
    margin: -4,
    padding: 4,
  },
  disabled: {
    backgroundColor: colors.grey[500],
    cursor: 'wait',
  },
}));

interface IState {
  isAutoSaving: boolean;
  isTextBlockEditingEnabled: boolean;
}

const selector = (state: IRootState) => ({
  isAutoSaving: state.senderBuild.isAutoSaving,
  isTextBlockEditingEnabled: state.senderBuild.isTextBlockEditingEnabled,
});

interface IProps {
  documentId: string;
  pageIndex: number;
  className: string;
  blockType: any;
  block: IBlock;
  signer?: ISigner;
  stopEditing: Function;
  blockIndex: number;
  zoom: number;
  pageHeight?: number;
  pageWidth?: number;
  selectedBlocks: number[];
  staticBlockMouseDown: boolean;
  staticMouseDownStateToggle: Function;
  mouseDownCoordinates: any;
  convertToGroup?: (block: IBlock, blockIndex: number) => void;
}

interface IBlockPos {
  x: number;
  y: number;
  width: number;
  height: number;
}

/**
 * For performance reasons while dragging and resizing, we keep a separate
 * record of the block position and size in the state of this component.
 * onDrag and onResize will only update this state so it doesn't reach
 * outside this component. onDragStop and onResizeStop will actually update block
 * in the redux store. We are also pre-calculating zoom so it doesn't need to calculate
 * on every re-render, but we need to recalculate when zoom changes using getDerivedStateFromProps.
 */

const BlockEditing = (props: IProps) => {
  const dispatch = useDispatch();
  const isMobile = isSmallScreenOrMobileApp();
  const { isAutoSaving, isTextBlockEditingEnabled }: IState = useShallowSelector(selector);
  const [disableAddBlock, setDisableAddBlock] = React.useState(false);
  const classes = useStyles();
  const blockRef = React.useRef<HTMLDivElement>(null);
  const resizedOrDragged = React.useRef<boolean>(false);
  const { signer, blockType, block, selectedBlocks, zoom } = props;
  const [blockPos, setBlockPos] = React.useState<IBlockPos>({
    x: props.block.x! * props.zoom,
    y: props.block.y! * props.zoom,
    width: props.block.width! * props.zoom,
    height: props.block.height! * props.zoom,
  });

  const isEditingMobileStrikeBlock = block.blockType === BLOCK_TYPE_KEYS.STRIKE && isMobile;

  React.useEffect(() => {
    setBlockPos({
      x: props.block.x! * props.zoom,
      y: props.block.y! * props.zoom,
      width: props.block.width! * props.zoom,
      height: props.block.height! * props.zoom,
    });
  }, [props.zoom, props.block.width, props.block.height]);

  React.useEffect(() => {
    if (!isAutoSaving) {
      setDisableAddBlock(false);
    } else {
      setDisableAddBlock(true);
    }
  }, [isAutoSaving]);

  React.useEffect(() => {
    if (props.staticBlockMouseDown) {
      let mouseDownEvent = new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        view: window,
        ...props.mouseDownCoordinates,
      });
      blockRef.current!.dispatchEvent(mouseDownEvent);
    }

    const keyboardHandler = (e: KeyboardEvent) => {
      if (block.isCreating) {
        return;
      }
      if (e.key.indexOf('Arrow') === 0 && !isTextBlockEditingEnabled) {
        const inc = ARROW_KEY_MOVEMENT_INCREMENT;
        let { x, y, width, height } = blockPos;
        if (e.key === 'ArrowLeft') {
          x = x >= inc ? x - inc : 0;
        } else if (e.key === 'ArrowRight') {
          if (x + width + inc <= props.pageWidth! * props.zoom) {
            x += inc;
          } else {
            x = props.pageWidth! * props.zoom - width;
          }
        } else if (e.key === 'ArrowUp') {
          y = y >= inc ? y - inc : 0;
        } else if (e.key === 'ArrowDown') {
          if (y + height + inc <= props.pageHeight! * props.zoom) {
            y += inc;
          } else {
            y = props.pageHeight! * props.zoom - height;
          }
        }
        setBlockPos({ ...blockPos, x, y });
        dragStop();
      }
    };
    document.addEventListener('keyup', keyboardHandler, false);
    return () => document.removeEventListener('keyup', keyboardHandler, false);
  }, [blockPos, isTextBlockEditingEnabled]);

  const editBlock = (updateObj: any) => {
    dispatch(actions.editBlock(props.documentId, props.pageIndex, props.blockIndex, updateObj));
  };

  // update just this components state
  const handleResize = (e: any, dir: any, ref: HTMLDivElement, delta: { width: number; height: number }, pos: any) => {
    setBlockPos({
      width: parseInt(ref.style.width!),
      height: parseInt(ref.style.height!),
      x: pos.x,
      y: pos.y,
    });
  };

  // update redux
  const resizeStop = (e: any, dir: any, ref: HTMLDivElement, delta: { width: number; height: number }, pos: any) => {
    resizedOrDragged.current = true;
    let resizeError = false;
    const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === block.blockType);
    let { fontSize } = block;
    let width = parseInt(ref.style.width!) / props.zoom;
    let height = parseInt(ref.style.height!) / props.zoom;
    let x = pos.x / props.zoom;
    let y = pos.y / props.zoom;
    if (width < 0 || isNaN(width) || width === null) {
      resizeError = true;
      width = currentBlockType?.size?.default.width / props.zoom;
    }
    if (height < 0 || isNaN(height) || height === null) {
      height = currentBlockType?.size?.default.height! / props.zoom;
      fontSize = height - 1;
      resizeError = true;
    }
    if (x < 0 || isNaN(x) || x === null) {
      x = 1;
      resizeError = true;
    }
    if (y < 0 || isNaN(y) || y === null) {
      y = 1;
      resizeError = true;
    }
    if (fontSize < 0 || fontSize === null || isNaN(fontSize) || fontSize >= height) {
      fontSize = height - 1;
      resizeError = true;
    }
    editBlock({
      width,
      height,
      x,
      y,
      fontSize: fontSize,
    });
    dispatch(actions.updateBlockSizingCache(height, width, currentBlockType?.key));
    if (resizeError) {
      setBlockPos({
        ...blockPos,
        x: x * props.zoom,
        y: y * props.zoom,
        height: height * props.zoom,
        width: width * props.zoom,
      });
    }
  };

  // update just this components state
  const drag = (e: any, pos: any) => {
    setBlockPos({
      ...blockPos,
      x: pos.x,
      y: pos.y,
    });
  };

  // update redux
  const dragStop = () => {
    resizedOrDragged.current = true;
    const x = blockPos.x / props.zoom;
    const y = blockPos.y / props.zoom;
    if (x !== block.x || y !== block.y) {
      editBlock({ x, y });
    }
  };

  const mouseDown = () => {
    resizedOrDragged.current = false;
  };

  const mouseUp = (e: any) => {
    // Following the mouseup, we need to reset the state on Block.tsx
    if (props.staticBlockMouseDown) {
      props.staticMouseDownStateToggle();
    }

    if (!resizedOrDragged.current) {
      props.stopEditing();
    }
  };

  const handleDeleteBlock = () => {
    dispatch(actions.deleteBlocks(props.documentId, props.pageIndex, [props.block.blockId!]));
  };

  const doubleClick = (e: any) => {
    e.stopPropagation();
    if (props.block.blockType === BLOCK_TYPE_KEYS.CHECKBOX) {
      editBlock({
        value: !props.block.value,
      });
    }
    if (props.block.blockType === BLOCK_TYPE_KEYS.TEXT) {
      dispatch(actions.enableTextEditing());
    }
  };

  const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === props.block.blockType);

  let blockTag = null;
  if (currentBlockType!.options!.assignRequired) {
    blockTag = selectedBlocks.length > 1 ? '' : <BlockTag signer={signer} blockType={blockType!} />;
  }

  const maxHeight = currentBlockType!.size!.max!.height * zoom;
  const maxWidth = currentBlockType!.size!.max!.width * zoom;
  const minHeight = currentBlockType!.size!.min!.height * zoom;
  const minWidth = currentBlockType!.size!.min!.width * zoom;

  const resize = {
    bottom: true,
    bottomLeft: true,
    bottomRight: true,
    left: true,
    right: true,
    top: true,
    topLeft: true,
    topRight: true,
  };

  const checkboxGroupBorder = props.selectedBlocks.length === 1 && props.block.groupId ? classes.selectedCheckbox : '';

  const handleConvertToGroup = (block: IBlock, index: number) => {
    if (!disableAddBlock) {
      setDisableAddBlock(true);
      props.convertToGroup!(block, index);
    }
  };

  const getResizeHandleStyles: (block: IBlock | IStrike) => HandleStyles = (block) => {
    let resizeStyle = {
      width: 4,
      height: 4,
      borderRadius: '50%',
      border: '2px solid',
      position: 'absolute',
      backgroundColor: 'white',
      color: signer?.color ? hex2rgba(signer.color, 1) : colors.grey[500],
    };

    let defaultStyle: any = {
      top: null,
      bottom: null,
      right: {
        ...resizeStyle,
        cursor: 'e-resize',
        top: isEditingMobileStrikeBlock ? 1 : -3,
      },
      left: {
        ...resizeStyle,
        cursor: 'w-resize',
        top: isEditingMobileStrikeBlock ? 1 : -3,
      },
      topRight: {
        ...resizeStyle,
        top: -5,
        right: -5,
      },
      bottomRight: {
        ...resizeStyle,
        bottom: -5,
        right: -5,
      },
      bottomLeft: {
        ...resizeStyle,
        bottom: -5,
        left: -5,
      },
      topLeft: {
        ...resizeStyle,
        top: -5,
        left: -5,
      },
    };

    if (block.blockType !== BLOCK_TYPE_KEYS.STRIKE) {
      defaultStyle.left = null;
      defaultStyle.right = null;
      return defaultStyle;
    }

    if ((block as IStrike).strikeType) {
      defaultStyle.top = {
        display: 'none',
      };
      defaultStyle.right = {
        display: 'none',
      };
      defaultStyle.bottom = {
        display: 'none',
      };
      defaultStyle.left = {
        display: 'none',
      };

      if ((block as IStrike).strikeType == StrikeType.Up) {
        defaultStyle.bottomRight = {
          display: 'none',
        };
        defaultStyle.topLeft = {
          display: 'none',
        };
      }
      if ((block as IStrike).strikeType == StrikeType.Down) {
        defaultStyle.topRight = {
          display: 'none',
        };
        defaultStyle.bottomLeft = {
          display: 'none',
        };
      }
    } else {
      defaultStyle.top = {
        display: 'none',
      };
      defaultStyle.bottom = {
        display: 'none',
      };
      defaultStyle.topRight = {
        display: 'none',
      };
      defaultStyle.bottomRight = {
        display: 'none',
      };
      defaultStyle.bottomLeft = {
        display: 'none',
      };
      defaultStyle.topLeft = {
        display: 'none',
      };
    }
    return defaultStyle;
  };

  switch (currentBlockType!.name) {
    case BLOCK_TYPE_KEYS.CHECKBOX:
      Object.keys(resize).forEach((value) => {
        resize[value] = false;
      });
      break;
    case BLOCK_TYPE_KEYS.STRIKE:
      resize.top = false;
      resize.bottom = false;
      resize.left = !(block as IStrike).strikeType;
      resize.right = !(block as IStrike).strikeType;
      resize.topLeft = (block as IStrike).strikeType === StrikeType.Down;
      resize.bottomRight = (block as IStrike).strikeType === StrikeType.Down;
      resize.topRight = (block as IStrike).strikeType === StrikeType.Up;
      resize.bottomLeft = (block as IStrike).strikeType === StrikeType.Up;
      break;
    default:
    // do nothing
  }

  if (block.isCreating) {
    for (const key in resize) {
      resize[key] = false;
    }
  }

  function getBackgroundColor() {
    let backgroundColor;

    if (signer) {
      backgroundColor = hex2rgba(signer!.color!, checkboxGroupBorder ? 1 : 0.2);
    } else if (block.blockType === BLOCK_TYPE_KEYS.STRIKE) {
      backgroundColor = hex2rgba('transparent', checkboxGroupBorder ? 1 : 0.2);
    } else if (block.blockType === BLOCK_TYPE_KEYS.RECTANGLE) {
      backgroundColor = hex2rgba(block!.bgColor!, block!.opacity! < 1 ? 0.33 : 1);
    } else {
      backgroundColor = hex2rgba(colors.grey[500], checkboxGroupBorder ? 1 : 0.2);
    }

    return backgroundColor;
  }

  let lastTapTime = 0;

  function handleTouch(e: any) {
    e.stopPropagation();

    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastTapTime;

    if (timeDifference < 500 && timeDifference > 0) {
      doubleClick(e);
      if (block.blockType === BLOCK_TYPE_KEYS.TEXT) {
        const inputRect = blockRef?.current?.getBoundingClientRect();
        const offsetY = window.scrollY || document.documentElement.scrollTop;

        const targetScrollPosition = offsetY + (inputRect?.top ?? 0) - 200;

        window.scrollTo({
          top: targetScrollPosition,
          behavior: 'smooth',
        });
      }
    }

    lastTapTime = currentTime;
  }

  return (
    <>
      <Rnd
        id={`rnd-${block.blockId}`}
        size={{
          width: blockPos.width,
          height: blockPos.height,
        }}
        position={{
          x: blockPos.x,
          y: blockPos.y,
        }}
        bounds="parent"
        className={classes.rnd}
        style={{ backgroundColor: checkboxGroupBorder ? signer!.color : undefined }}
        onResize={handleResize}
        onResizeStop={resizeStop}
        onDrag={drag}
        onDragStop={dragStop}
        cancel=".ds--ignore-react-rnd"
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
        disableDragging={block.isCreating || isTextBlockEditingEnabled}
        enableResizing={resize}
        data-spec={`rnd-${block.blockId}`}
        resizeHandleStyles={getResizeHandleStyles(block)}
        resizeHandleClasses={{
          top: 'handler',
          right: 'handler',
          bottom: 'handler',
          left: 'handler',
          topRight: 'handler',
          bottomRight: 'handler',
          bottomLeft: 'handler',
          topLeft: 'handler',
        }}
      >
        <div
          {...(block.blockId ? { id: `${block.blockType}_${block.blockId}` } : {})}
          data-spec="block-editing"
          ref={blockRef}
          id={`block-${block.blockId}`}
          className={twMerge(
            `${classes.block} ${block.blockType} ${checkboxGroupBorder}`,
            isEditingMobileStrikeBlock && 'py-[5px]'
          )}
          onMouseDown={mouseDown}
          onMouseUp={mouseUp}
          onDoubleClick={doubleClick}
          onTouchEnd={handleTouch}
          style={{
            backgroundColor: getBackgroundColor(),
          }}
        >
          <BlockInner
            block={block}
            blockType={blockType}
            zoom={props.zoom}
            color={signer ? signer!.color! : colors.grey[500]}
            signer={signer}
            isSelected
            isTextBlockEditingEnabled={isTextBlockEditingEnabled}
            documentId={props.documentId}
            blockIndex={props.blockIndex}
          />
          {blockTag}
        </div>
        {props.block.groupId && (
          <div className={classes.groupButton} onClick={handleDeleteBlock} id="removeCheckbox">
            <RemoveIcon />
          </div>
        )}
        {!props.block.groupId &&
          props.block.blockType === BLOCK_TYPE_KEYS.CHECKBOX &&
          !props.block.readOnly &&
          !isMobile && (
            <div
              className={disableAddBlock ? cx(classes.groupButton, classes.disabled) : classes.groupButton}
              onClick={() => {
                handleConvertToGroup(props.block, props.blockIndex);
              }}
              data-spec={'block-editing-add-block'}
            >
              <AddIcon fontSize="small" />
            </div>
          )}
      </Rnd>
    </>
  );
};

export default BlockEditing;
